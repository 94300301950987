import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../utils'

export const mockMfaCheck = {
  "subscription": "83ef9d05-4169-4ef9-9657-0e86b1eab1de",
  "mfa_enabled": "true"
  
}

export interface AzureCustomerMfaCheckRecord {
  subscription: string
  mfa_enabled: string
}

interface ApiResponse {
  data: AzureCustomerMfaCheckRecord | null
  error: string | null
}

interface FetchOptions {
  token: string
  supabaseClient: SupabaseClient
}

interface GetAzureCustomerMfaCheckProps {
  state: RequestState
  error: string | null
  data?: ApiResponse['data']
  reset: () => unknown
  checkMfa: (customerId: string,  subscriptionId: string, fetchOption: FetchOptions, shouldMock: boolean) => Promise<ApiResponse>
}

const useGetAzureCustomersMfaCheck = create<GetAzureCustomerMfaCheckProps>((set, get) => ({
  state: RequestState.IDLE,
  error: null,
  data: null,
  reset: () => {
    set({ state: RequestState.IDLE, error: null })
  },
  checkMfa: async (customerId: string, subscriptionId: string, fetchOption: FetchOptions, shouldMock = false) => {
    set({ state: RequestState.LOADING, error: null, data: null })
    try {
      const apiFetch = buildFetch(fetchOption.supabaseClient)

      if (shouldMock) {
        // simulate network for mocks
        await new Promise((done) => setTimeout(() => done(null), 1000))
        set({ state: RequestState.SUCCESS, error: null, data: mockMfaCheck })
        return {
          data: mockMfaCheck,
          error: null
        }
      }

      const { data } = await apiFetch<AzureCustomerMfaCheckRecord>(
        `${ENDPOINT}/mfa/${customerId}/${subscriptionId}`,
        buildOptions('GET', { Authorization: `Bearer ${fetchOption.token}` })
      )

      set({ state: RequestState.SUCCESS, error: null, data })

      return {
        data,
        error: null
      }
    } catch (error) {
      const { error: _error } = error && error.message ? JSON.parse(error.message) : 'error'
      set({ state: RequestState.ERROR, error: _error.type })
      return {
        data: null,
        error: _error.type
      }
    }
  }
}))

export { useGetAzureCustomersMfaCheck }
