import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../../utils'

export const mockEvents = [
  {
    "eventType": "NetworkConnectionsToCryptoMiningPools",
    "severity": "High",
    "confidenceLevel": "High",
    "displayName": "[TestAlert] | Action Required: Virtual machine connecting to crypto currency mining pool Detected",
    "description": "[TestAlert] | analysis of azure resource network activity detected the resource was connecting to a crypto currency mining pool. this would often be an indication that your azure resource is compromised.",
    "country": "US",
    "valueAddedResellerTenantId": "007f645a-12ac-424a-9c44-1754908aaf18",
    "valueAddedResellerFriendlyName": "Test Reseller-3de67d67-88e8-42c0-88ea-13bfc2fc2f55",
    "subscriptionName": "Test Azure Subscription-3de67d67-88e8-42c0-88ea-13bfc2fc2f55",
    "affectedResources": [
      {
        "azureResourceId": "/subscriptions/subscription-id/resourceGroups/resourcegroup-name/providers/Microsoft.Compute/virtualMachines/vm-name",
        "type": "AzureResource"
      }
    ],
    "additionalDetails": {
      "resourceId": "/subscriptions/subcription-id/resourceGroups/resourcegroup-name/providers/Microsoft.Compute/virtualMachines/vm-name",
      "resourceType": "Virtual Machine",
      "vM_IP": [
        "vm-ip"
      ],
      "miningPool_IP": [
        "mining-pool-ip"
      ],
      "connectionCount": "5",
      "cryptoCurrencyMiningPoolDomainName": "pool-name.com"
    },
    "isTest": "true",
    "activityLogs": [],
    "eventTime": "2023-10-23T16:39:54.17",
    "eventId": "26837a46-e6c2-4bac-8233-63b0e8b2dd67-4dc7c242-804b-4ea0-b350-722d0efa763c",
    "partnerTenantId": "3de67d67-88e8-42c0-88ea-13bfc2fc2f55",
    "partnerFriendlyName": "Test Partner-3de67d67-88e8-42c0-88ea-13bfc2fc2f55",
    "customerTenantId": "499a61cd-c129-4503-b124-13349dd2c9be",
    "customerFriendlyName": "Test Customer-3de67d67-88e8-42c0-88ea-13bfc2fc2f55",
    "subscriptionId": "26837a46-e6c2-4bac-8233-63b0e8b2dd67",
    "subscriptionType": "Test",
    "entityId": "26837a46-e6c2-4bac-8233-63b0e8b2dd67-4dc7c242-804b-4ea0-b350-722d0efa763c",
    "entityName": "vm-name",
    "entityUrl": "/subscriptions/subscription-id/resourceGroups/resourcegroup-name/providers/Microsoft.Compute/virtualMachines/vm-name",
    "hitCount": "5",
    "catalogOfferId": "MS-AZR-0017G",
    "eventStatus": "Active",
    "serviceName": "Microsoft.Compute",
    "resourceName": "virtualMachines",
    "resourceGroupName": "resourcegroup-name",
    "firstOccurrence": "2023-10-23T16:39:54.17",
    "lastOccurrence": "2023-10-23T16:39:54.17",
    "resolvedReason": "None",
    "resolvedOn": "9999-12-31T23:59:59.9966667",
    "resolvedBy": "",
    "firstObserved": "2023-10-23T16:39:54.17",
    "lastObserved": "2023-10-23T16:39:54.17"
  }
] as FraudEventResponse[]

function buildMockEvents() {
  return Array(200).fill(0).map((_, i) => (
    {
      ...mockEvents[0],
      id: `mock-event-${i}`
    }
  ))
}

export type FraudEventResponse = Record<string, any>

interface ApiResponse {
  data: FraudEventResponse[]
  error: string | null
}

interface FetchOptions {
  token: string
  supabaseClient: SupabaseClient
}

interface GetPartnerCenterEventsProps {
  state: RequestState
  error: string | null
  data?: ApiResponse['data']
  reset: () => unknown
  getNotifications: (fetchOption: FetchOptions, shouldMock: boolean) => Promise<ApiResponse>
}

const useGetPartnerCenterEvents = create<GetPartnerCenterEventsProps>((set, get) => ({
  state: RequestState.IDLE,
  error: null,
  data: [],
  reset: () => {
    set({ state: RequestState.IDLE, error: null })
  },
  getNotifications: async (fetchOption: FetchOptions, shouldMock = false) => {
    set({ state: RequestState.LOADING, error: null, data: [] })
    try {
      const apiFetch = buildFetch(fetchOption.supabaseClient)

      if (shouldMock) {
        // simulate network for mocks
        const mockedEvents = buildMockEvents()
        await new Promise((done) => setTimeout(() => done(null), 1000))
        set({ state: RequestState.SUCCESS, error: null, data: mockedEvents })
        return {
          data: mockEvents,
          error: null
        }
      }

      const { data } = await apiFetch<FraudEventResponse[]>(
        `${ENDPOINT}/events`,
        buildOptions('GET', { Authorization: `Bearer ${fetchOption.token}` })
      )

      set({ state: RequestState.SUCCESS, error: null, data })

      return {
        data,
        error: null
      }
    } catch (error) {
      const { error: _error } = error && error.message ? JSON.parse(error.message) : 'error'
      set({ state: RequestState.ERROR, error: _error.type })
      return {
        data: [],
        error: _error.type
      }
    }
  }
}))

export { useGetPartnerCenterEvents }
