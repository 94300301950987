import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../utils'

export const mockActivityLogs = [
  {
    "value": [
      {
        "authorization": {
          "action": "microsoft.support/supporttickets/write",
          "role": "Subscription Admin",
          "scope": "/subscriptions/089bd33f-d4ec-47fe-8ba5-0753aa5c5b33/resourceGroups/MSSupportGroup/providers/microsoft.support/supporttickets/115012112305841"
        },
        "caller": "admin@contoso.com",
        "claims": {
          "aud": "https://management.core.windows.net/",
          "iss": "https://sts.windows.net/72f988bf-86f1-41af-91ab-2d7cd011db47/",
          "iat": "1421876371",
          "nbf": "1421876371",
          "exp": "1421880271",
          "ver": "1.0",
          "http://schemas.microsoft.com/identity/claims/tenantid": "1e8d8218-c5e7-4578-9acc-9abbd5d23315",
          "http://schemas.microsoft.com/claims/authnmethodsreferences": "pwd",
          "http://schemas.microsoft.com/identity/claims/objectidentifier": "2468adf0-8211-44e3-95xq-85137af64708",
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/upn": "admin@contoso.com",
          "puid": "20030000801A118C",
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier": "9vckmEGF7zDKk1YzIY8k0t1_EAPaXoeHyPRn6f413zM",
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname": "John",
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname": "Smith",
          "name": "John Smith",
          "groups": "cacfe77c-e058-4712-83qw-f9b08849fd60,7f71d11d-4c41-4b23-99d2-d32ce7aa621c,31522864-0578-4ea0-9gdc-e66cc564d18c",
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": "admin@contoso.com",
          "appid": "c44b4083-3bq0-49c1-b47d-974e53cbdf3c",
          "appidacr": "2",
          "http://schemas.microsoft.com/identity/claims/scope": "user_impersonation",
          "http://schemas.microsoft.com/claims/authnclassreference": "1"
        },
        "correlationId": "1e121103-0ba6-4300-ac9d-952bb5d0c80f",
        "description": "",
        "eventDataId": "44ade6b4-3813-45e6-ae27-7420a95fa2f8",
        "eventName": {
          "value": "EndRequest",
          "localizedValue": "End request"
        },
        "httpRequest": {
          "clientRequestId": "27003b25-91d3-418f-8eb1-29e537dcb249",
          "clientIpAddress": "192.168.35.115",
          "method": "PUT"
        },
        "id": "/subscriptions/089bd33f-d4ec-47fe-8ba5-0753aa5c5b33/resourceGroups/MSSupportGroup/providers/microsoft.support/supporttickets/115012112305841/events/44ade6b4-3813-45e6-ae27-7420a95fa2f8/ticks/635574752669792776",
        "level": "Informational",
        "resourceGroupName": "MSSupportGroup",
        "resourceProviderName": {
          "value": "microsoft.support",
          "localizedValue": "microsoft.support"
        },
        "operationId": "1e121103-0ba6-4300-ac9d-952bb5d0c80f",
        "operationName": {
          "value": "microsoft.support/supporttickets/write",
          "localizedValue": "microsoft.support/supporttickets/write"
        },
        "properties": {
          "statusCode": "Created"
        },
        "status": {
          "value": "Succeeded",
          "localizedValue": "Succeeded"
        },
        "subStatus": {
          "value": "Created",
          "localizedValue": "Created (HTTP Status Code: 201)"
        },
        "eventTimestamp": "2015-01-21T22:14:26.9792776Z",
        "submissionTimestamp": "2015-01-21T22:14:39.9936304Z",
        "subscriptionId": "089bd33f-d4ec-47fe-8ba5-0753aa5c5b33"
      }
    ],
    "nextLink": "https://management.azure.com/########-####-####-####-############$skiptoken=######"
  }
]

export interface ActivityLog {
  value: any // TODO
}

interface ApiResponse {
  data: ActivityLog[]
  error: string | null
}

interface FetchOptions {
  token: string
  supabaseClient: SupabaseClient
}

interface GetActivityLogs {
  state: RequestState
  error: string | null
  data: ApiResponse['data']
  reset: () => unknown
  getActivityLogs: (customerId: string, fetchOption: FetchOptions, shouldMock: boolean) => Promise<ApiResponse>
}

const useGetActivityLogs = create<GetActivityLogs>((set, get) => ({
  state: RequestState.IDLE,
  error: null,
  data: [],
  reset: () => {
    set({ state: RequestState.IDLE, error: null })
  },
  getActivityLogs: async (customerId: string, fetchOption: FetchOptions, shouldMock = false) => {
    set({ state: RequestState.LOADING, error: null, data: [] })
    try {
      const apiFetch = buildFetch(fetchOption.supabaseClient)

      if (shouldMock) {
        // simulate network for mocks
        await new Promise((done) => setTimeout(() => done(null), 1000))
        set({ state: RequestState.SUCCESS, error: null, data: mockActivityLogs })
        return {
          data: mockActivityLogs,
          error: null
        }
      }

      const { data } = await apiFetch<ActivityLog[]>(
        `${ENDPOINT}/customer/${customerId}/activity-logs`,
        buildOptions('GET', { Authorization: `Bearer ${fetchOption.token}` })
      )

      set({ state: RequestState.SUCCESS, error: null, data })

      return {
        data,
        error: null
      }
    } catch (error) {
      const { error: _error } = error && error.message ? JSON.parse(error.message) : 'error'
      set({ state: RequestState.ERROR, error: _error.type })
      return {
        data: [],
        error: _error.type
      }
    }
  }
}))

export { useGetActivityLogs }
