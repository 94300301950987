import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../../utils'

export interface TestEventResponse {}

interface ApiResponse {
  data: TestEventResponse[]
  error: string | null
}

interface FetchOptions {
  token: string
  supabaseClient: SupabaseClient
}

interface PostTestEventProps {
  state: RequestState
  error: string | null
  data: ApiResponse['data']
  actions: {
    reset: () => unknown
    createTestEvent: (options: FetchOptions) => Promise<any>
  }
}

const usePostTestEvent = create<PostTestEventProps>((set, get) => ({
  state: RequestState.IDLE,
  error: null,
  data: null,
  actions: {
    reset: () => {
      set({ state: RequestState.IDLE, data: null, error: null })
    },
    createTestEvent: async (options: FetchOptions) => {
      set({ state: RequestState.LOADING, data: null, error: null })
      try {
        const apiFetch = buildFetch(options.supabaseClient)
        const data = await apiFetch<TestEventResponse[]>(
          `${ENDPOINT}/test-event`,
          buildOptions(
            'POST',
            { Authorization: `Bearer ${options.token}` }
          )
        )
        set({ data: data as any, state: RequestState.SUCCESS, error: null })

        return {
          data: data.data,
          error: null
        }
      } catch (error) {
        const { error: _error } = error && error.message ? JSON.parse(error.message) : 'error'
        set({ state: RequestState.ERROR, error: _error.type })
        return {
          data: [],
          error: _error.type
        }
      }
    }
  }
}))

export { usePostTestEvent }
