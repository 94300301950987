import React from 'react'

interface IconProps {
  width?: number
  height?: number
  vBox?: string
  style?: Record<string, string>
}

function Logo(props: IconProps) {
  const width = props.width || '100%'
  const height = props.height || '100%'
  const viewbox = props.vBox || `0 0 ${1880} ${1880}`
  const style = props.style || {}

  return (
    <svg width={width} height={height} viewBox={viewbox} fill='currentColor' style={style}>
      <path
        fill='#77DBFE'
        opacity='1.000000'
        stroke='none'
        d='M897.468628,1881.000000 
        C890.651001,1880.334961 884.302979,1879.659668 877.952759,1879.006470 
        C855.808472,1876.728516 833.622375,1874.796143 811.527893,1872.109375 
        C773.857727,1867.528442 736.815125,1859.596191 700.145813,1849.926636 
        C649.356567,1836.534058 600.084167,1818.883301 552.237976,1797.192017 
        C504.367157,1775.489624 458.651062,1749.951660 415.085510,1720.578735 
        C379.994263,1696.919434 346.683807,1670.959351 315.098389,1642.767090 
        C277.914337,1609.577637 243.711182,1573.559204 211.902969,1535.217773 
        C209.015808,1531.737671 208.967255,1529.601318 212.359070,1526.211792 
        C385.979858,1352.695557 559.470886,1179.049561 733.052002,1005.493713 
        C767.555542,970.995239 802.331543,936.768799 837.087219,902.523804 
        C839.921570,899.731079 841.087219,896.979492 841.085938,892.973022 
        C840.991760,599.680725 841.000000,306.388458 841.000122,13.096197 
        C841.000122,5.197905 841.016846,5.468685 848.950623,5.002681 
        C864.811035,4.071086 880.652161,2.811465 896.750610,1.343647 
        C926.020874,1.000000 955.041809,1.000000 984.531372,0.999999 
        C986.369629,1.338197 987.724731,1.887437 989.111023,1.982317 
        C1004.983521,3.068722 1020.860474,4.089603 1036.736938,5.118427 
        C1039.675903,5.308878 1041.423584,6.492707 1041.025146,9.791663 
        C1040.866455,11.105617 1041.000000,12.454810 1041.000000,13.787956 
        C1041.000000,306.580200 1041.010376,599.372437 1040.903687,892.164612 
        C1040.901978,896.694458 1042.176880,899.820312 1045.386597,903.026978 
        C1205.720093,1063.203369 1365.931641,1223.502075 1526.243164,1383.700562 
        C1573.846802,1431.270508 1621.654541,1478.636597 1669.545898,1525.916748 
        C1673.220825,1529.544922 1672.913818,1531.771484 1669.989014,1535.327515 
        C1643.107544,1568.011353 1614.216675,1598.835083 1583.380127,1627.775391 
        C1549.234985,1659.820679 1512.764526,1689.030029 1474.155273,1715.591309 
        C1435.538818,1742.157471 1395.208008,1765.741577 1353.084717,1786.243286 
        C1300.133911,1812.014771 1245.445435,1833.389648 1188.331177,1847.868164 
        C1155.503784,1856.189941 1122.324219,1863.364624 1089.007202,1869.430054 
        C1065.522583,1873.705688 1041.557373,1875.406006 1017.773132,1877.941650 
        C1006.724548,1879.119507 995.596313,1879.549561 984.252075,1880.659302 
        C955.312439,1881.000000 926.624878,1881.000000 897.468628,1881.000000 
      M1088.500000,1230.000000 
        C1039.410889,1180.910278 990.321777,1131.820679 941.146606,1082.644897 
        C791.457397,1231.839111 642.240356,1380.562622 492.952911,1529.356323 
        C786.564270,1751.740112 1160.505615,1709.190674 1388.037476,1529.536987 
        C1288.334717,1429.834473 1188.667236,1330.167358 1088.500000,1230.000000 
      z'
      />
      <path
        fill='#77DBFE'
        opacity='1.000000'
        stroke='none'
        d='M1.000005,902.468628 
        C1.327018,900.251709 1.813944,898.516296 1.956650,896.753113 
        C3.642446,875.923950 4.998981,855.064148 6.983963,834.264099 
        C10.814965,794.119934 18.405001,754.602905 28.036625,715.485168 
        C40.727318,663.943420 57.730438,613.846497 78.950363,565.171082 
        C102.952980,510.112579 131.927292,457.831818 165.949982,408.344299 
        C185.844406,379.406982 206.958359,351.384613 230.404831,325.229126 
        C247.178909,306.516937 263.955505,287.728424 281.863617,270.126343 
        C333.544006,219.329041 390.378082,175.006516 452.389069,137.415405 
        C493.850098,112.281685 536.860779,90.170753 581.818970,71.983849 
        C599.984070,64.635536 618.463074,58.063061 636.807556,51.159534 
        C637.874512,50.757984 639.015930,50.554371 640.733643,50.095402 
        C640.829712,52.044304 640.989014,53.770714 640.989319,55.497162 
        C641.001892,123.496902 640.955688,191.496750 641.102417,259.496185 
        C641.111938,263.909515 639.548950,265.684692 635.634827,267.455566 
        C589.109680,288.504913 545.286804,314.202179 504.027802,344.318634 
        C467.208282,371.194519 433.280914,401.348419 402.066833,434.493530 
        C363.729858,475.202332 330.514709,519.806396 302.301361,568.125610 
        C274.351501,615.993530 252.026962,666.344971 235.427109,719.213806 
        C224.740005,753.251160 215.921677,787.792175 211.056061,823.161682 
        C207.671219,847.767029 204.955154,872.502075 203.078171,897.265381 
        C201.522614,917.787903 200.747498,938.457947 201.248230,959.023132 
        C201.751907,979.709045 204.030304,1000.367126 205.935699,1021.000061 
        C209.463608,1059.202637 216.683746,1096.778931 227.084747,1133.664551 
        C233.248489,1155.523438 240.747269,1177.009155 247.830826,1198.601929 
        C248.889648,1201.829468 248.722260,1203.894043 246.158600,1206.449463 
        C197.442368,1255.006714 148.839035,1303.677246 100.202896,1352.314941 
        C99.289024,1353.228882 98.262123,1354.029663 96.721924,1355.379272 
        C92.930977,1347.382202 89.227112,1339.830200 85.743340,1332.177979 
        C61.250076,1278.377075 41.380650,1222.914429 27.957893,1165.335571 
        C21.273516,1136.661987 15.970809,1107.628296 11.130807,1078.576172 
        C7.787322,1058.506958 6.095839,1038.148438 4.071049,1017.878784 
        C2.834303,1005.498047 2.364722,993.040588 1.275290,980.308838 
        C1.000000,954.312439 1.000000,928.624878 1.000005,902.468628 
      z'
      />
      <path
        fill='#77DBFE'
        opacity='1.000000'
        stroke='none'
        d='M1881.000000,979.531372 
        C1880.672974,981.748291 1880.186157,983.483582 1880.043335,985.246765 
        C1878.357544,1006.075623 1877.001221,1026.935059 1875.016235,1047.734863 
        C1871.185059,1087.878296 1863.595703,1127.394653 1853.964111,1166.511719 
        C1841.273438,1218.052612 1824.392090,1268.201782 1803.005615,1316.803467 
        C1798.183960,1327.760498 1793.108032,1338.605957 1788.112061,1349.485596 
        C1787.368042,1351.106201 1786.420044,1352.633179 1785.125732,1355.014160 
        C1781.849365,1351.880127 1778.984375,1349.239990 1776.230103,1346.489014 
        C1753.424683,1323.711182 1730.621094,1300.931396 1707.854126,1278.115234 
        C1687.431885,1257.648804 1667.064453,1237.127686 1646.653564,1216.650146 
        C1643.133789,1213.118896 1639.587769,1209.607300 1635.921021,1206.231079 
        C1633.424438,1203.932373 1633.002441,1201.717896 1634.254395,1198.398193 
        C1645.801758,1167.780273 1655.452148,1136.549683 1662.143555,1104.519653 
        C1666.744629,1082.496094 1670.692993,1060.301270 1673.913940,1038.035522 
        C1676.449829,1020.506042 1677.618652,1002.766418 1679.023682,985.090698 
        C1679.760498,975.821838 1679.652954,966.487854 1680.016602,957.186768 
        C1680.262573,950.893860 1681.142456,944.589478 1680.910156,938.320923 
        C1680.135498,917.418640 1679.724731,896.463684 1677.844116,875.649048 
        C1672.196533,813.134949 1658.991455,752.182373 1637.843018,693.044678 
        C1613.434570,624.791321 1579.780029,561.451233 1536.854004,502.975067 
        C1509.770508,466.080658 1479.506470,432.043701 1446.162842,400.814056 
        C1401.175537,358.678741 1351.736206,322.491638 1297.447144,293.175110 
        C1280.040405,283.775269 1262.041748,275.475220 1244.435425,266.435089 
        C1242.833862,265.612732 1241.145630,263.056000 1241.141479,261.300690 
        C1240.975952,191.969101 1240.999756,122.637070 1241.014160,53.305115 
        C1241.014282,52.354427 1241.206055,51.403786 1241.392944,49.665405 
        C1255.006226,54.645729 1268.301880,59.283459 1281.430664,64.353233 
        C1331.659302,83.749435 1379.829468,107.410179 1425.948120,135.170715 
        C1463.173706,157.578217 1498.650146,182.506210 1532.456665,209.848267 
        C1560.320801,232.384354 1586.958374,256.270142 1611.989014,281.865936 
        C1662.654419,333.675415 1707.021240,390.456146 1744.633179,452.452240 
        C1769.609985,493.621979 1791.383301,536.423889 1809.797729,580.915833 
        C1828.114990,625.173218 1843.460327,670.465454 1854.205566,717.141663 
        C1860.774170,745.674255 1866.079346,774.534424 1870.871826,803.426636 
        C1874.201538,823.498596 1875.904663,843.854248 1877.929321,864.123657 
        C1879.166016,876.503601 1879.635376,888.960266 1880.724609,901.691223 
        C1881.000000,927.687561 1881.000000,953.375122 1881.000000,979.531372 
      z'
      />
      <path
        fill='#77DBFE'
        opacity='1.000000'
        stroke='none'
        d='M1358.156006,930.843750 
        C1320.079834,892.768005 1282.274292,854.921631 1244.380005,817.164185 
        C1241.946289,814.739136 1240.928101,812.326233 1240.930908,808.876221 
        C1241.015869,705.387939 1241.000000,601.899597 1241.000000,498.411255 
        C1241.000000,496.776794 1241.000000,495.142334 1241.000000,491.910950 
        C1319.083618,545.064636 1379.410278,612.188965 1421.712524,695.302490 
        C1477.753662,805.409668 1492.866089,921.385010 1471.377686,1044.066772 
        C1433.022095,1005.710632 1395.714111,968.402161 1358.156006,930.843750 
      z'
      />
      <path
        fill='#77DBFE'
        opacity='1.000000'
        stroke='none'
        d='M515.431824,608.446289 
        C532.662964,586.515869 551.225037,566.273804 571.253723,547.398499 
        C591.520935,528.298340 613.183716,510.905029 636.222473,495.242981 
        C637.424561,494.425781 638.720398,493.746399 640.662842,492.592987 
        C640.803040,494.803192 640.987488,496.355652 640.987732,497.908112 
        C641.001282,602.565796 641.020813,707.223450 640.873291,811.880920 
        C640.869812,814.352966 639.615845,817.457397 637.876770,819.201477 
        C563.743652,893.547729 489.489838,967.773560 415.240997,1042.004395 
        C414.221008,1043.024048 413.075073,1043.917725 411.174438,1045.583130 
        C409.712067,1037.306030 408.247223,1029.891968 407.110199,1022.428162 
        C402.114929,989.637451 399.890259,956.640320 401.081024,923.499084 
        C402.215942,891.913269 406.093872,860.620850 412.592316,829.650574 
        C422.010651,784.764648 437.012695,741.816284 457.311249,700.751221 
        C473.442230,668.117493 492.873047,637.491272 515.431824,608.446289 
      z'
      />
    </svg>
  )
}

export { Logo }
