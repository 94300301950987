import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../../utils'
import { ContainVMAction, CollectActivityLogsAction, CollectAuditLogsAction } from './utils'

type Action = ContainVMAction | CollectActivityLogsAction | CollectAuditLogsAction

export interface OptionOk {
  Ok: {
    details: string
    additionalInfo?: AdditionalInfo
  }
}

export interface AdditionalInfo {
  type: AdditionalInfoType // logsCollected only. body should be union based on type
  body: DownloadLogsBody | PortalUrlsBody
}

export enum AdditionalInfoType {
  DOWNLOAD_LOGS = 'logsCollected',
  PORTAL_LINKS = 'portalLinks',
}

export interface DownloadLogsBody {
  downloadUrl: string,
}

export interface PortalUrlsBody {
  links: PortalUrl[],
}

export interface PortalUrl {
  url: string,
  name: string,
}

export interface OptionErr {
  Err: {
    error: string
  }
}

export interface RemediatePayload {
  tenantId: string
  eventId: string
  dryRun: boolean
  actions: Action[]
}

export interface OptionSubscriptionRequired {
  SubscriptionRequired: {
    reason: string
    redirect: string
  }
}

interface ResultStep {
  result: OptionOk | OptionErr | OptionSubscriptionRequired
  step: string
}

export interface RemediationResponse {
  Ok?: {
    details: string
  }
  Err?: {
    details: string
  }
  action: Action
  dryRun: boolean
  steps: ResultStep[]
}

interface ApiResponse {
  data: RemediationResponse[]
  error: string | null
}

interface FetchOptions {
  token: string
  supabaseClient: SupabaseClient
}

interface PostRemediationProps {
  state: RequestState
  error: string | null
  data: ApiResponse['data']
  actions: {
    reset: () => unknown
    remediate: (payload: RemediatePayload, options: FetchOptions) => Promise<any>
  }
}

const usePostRemediation = create<PostRemediationProps>((set, get) => ({
  state: RequestState.IDLE,
  error: null,
  data: null,
  actions: {
    reset: () => {
      set({ state: RequestState.IDLE, data: null, error: null })
    },
    remediate: async (payload: RemediatePayload, options: FetchOptions) => {
      set({ state: RequestState.LOADING, data: null, error: null })
      try {
        const apiFetch = buildFetch(options.supabaseClient)
        const data = await apiFetch<RemediationResponse[]>(
          `${ENDPOINT}/remediate/${payload.tenantId}`,
          buildOptions(
            'POST',
            { Authorization: `Bearer ${options.token}` },
            JSON.stringify(payload)
          )
        )
        set({ data: data as any, state: RequestState.SUCCESS, error: null })

        return {
          data: data.data,
          error: null
        }
      } catch (error) {
        const { error: _error } = error && error.message ? JSON.parse(error.message) : 'error'
        set({ state: RequestState.ERROR, error: _error.type })
        return {
          data: [],
          error: _error.type
        }
      }
    }
  }
}))

export { usePostRemediation }
