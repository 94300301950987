import React from 'react'
import { SupabaseClient } from '@supabase/supabase-js'

import { useToast } from '../../components/ui/toast/use-toast'
import { ToastAction } from '../../components/ui/toast/toast'
import { ENDPOINT, buildFetch, buildOptions } from '../../store/utils'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'

export type Error401 = 'supabase' | 'access-partner' | 'access-customer'
export const error401Type = [
  'supabase',
  'access-partner',
  'access-customer'
]

export function use401Handlers(
  error: Error401,
  request: {
    url: string
    options: RequestInit,
    data?: Record<string, string>,
  },
  supabaseClient: SupabaseClient,
  noop: boolean = false
) {
  const { toast, dismiss } = useToast()
  const apiFetch = buildFetch(supabaseClient)
  const [isLoading, setLoading] = React.useState(false)

  React.useEffect(() => {
    // we can't change the order of the hook being called, so we allow aborting early
    if (noop) { return }
    if (error === 'access-customer') {
      if (!(request.data || {}).customerId) { return }

      toast({
        title: (
          <div className='flex items-center'>
            <ExclamationTriangleIcon width={24} height={24} className='text-yellow-500' />
            <p className='ml-2'>Credentials Needed</p>
          </div>
        ) as any,
        description: 'Please login to the customer\'s account.',
        action: (
          <div className='flex justify-between w-full'>
            <div className='w-[20%]'>
              {isLoading && (
                <div className='w-full h-full flex items-end pb-2'>
                  <span className='animate-ping absolute inline-flex h-[10px] w-[10px] rounded-full bg-op-blue-light opacity-75'></span>
                </div>
              )}
            </div>
            <ToastAction
              className='border-none bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80 mt-4 self-end'
              altText='Credentials needed'
              onClick={async () => {
                setLoading(true)
                const { data } = await supabaseClient.auth.refreshSession()
                if (data && data.session) {
                  const options = buildOptions(
                    'GET',
                    { Authorization: `Bearer ${data.session.access_token}` },
                  )
                  const { data: resData } = await apiFetch(`${ENDPOINT}/login/tenant/${request.data.customerId}`, options)
                  location.href = resData as string
                }
              }}>
              Login
            </ToastAction>
          </div>
        )
      })
    }
    if (error === 'access-partner') {
      toast({
        title: (
          <div className='flex items-center'>
            <ExclamationTriangleIcon width={24} height={24} className='text-yellow-500' />
            <p className='ml-2'>Credentials Needed</p>
          </div>
        ) as any,
        description: 'Please login to your partner center account.',
        action: (
          <div className='flex justify-between w-full'>
            <div className='w-[20%]'>
              {isLoading && (
                <div className='w-full h-full flex items-end pb-2'>
                  <span className='animate-ping absolute inline-flex h-[10px] w-[10px] rounded-full bg-op-blue-light opacity-75'></span>
                </div>
              )}
            </div>
            <ToastAction
              className='border-none bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80 mt-4 self-end'
              altText='Credentials needed'
              onClick={async () => {
                setLoading(true)
                const { data } = await supabaseClient.auth.refreshSession()
                if (data && data.session) {
                  const options = buildOptions(
                    'GET',
                    { Authorization: `Bearer ${data.session.access_token}` },
                  )
                  const { data: resData } = await apiFetch(`${ENDPOINT}/login`, options)
                  location.href = resData as string
                }
              }}>
              Login
            </ToastAction>
          </div>
        )
      })
    }
    if (error === 'supabase') {
      ;(async () => {
        const { data } = await supabaseClient.auth.refreshSession()
        if (data && data.session) {
          const accessToken = data.session.access_token

          return apiFetch(request.url, {
            ...request.options,
            headers: {
              ...request.options.headers,
              Authorization: `Bearer ${accessToken}`
            }
          })
        }
      })()
    }
  }, [error])
}

export function checkErrorsFor401(
  errors: string[],
  opts: {
    url: string
    options: RequestInit
    data?: Record<string, string>
  },
  supabaseClient: SupabaseClient,
  shouldSkip = false
) {
  // only want to show 1 notification at a time
  const [firstError] = errors.filter((e) => error401Type.includes(e))
  use401Handlers(
    firstError as Error401,
    opts,
    supabaseClient,
    shouldSkip
  )
}
