
export enum ActionType {
  containVirtualMachine = 'containVirtualMachine',
  collectActivityLogs = 'collectActivityLogs',
  collectAuditLogs = 'collectAuditLogs'
}

export type ContainVMAction = {
  action: 'containVirtualMachine'
  resource: {
    id: string
    name: string
    resourceGroup: string
    subscriptionId: string
  }
}

export type CollectActivityLogsAction = {
  action: 'collectActivityLogs'
  subscription_id: string
}

export type CollectAuditLogsAction = {
  action: 'collectAuditLogs'
  tenant_id: string
}