import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../../utils'

const mockRequestPermissionsResponse = {
  id: '1',
  name: 'Test Permissions Request',
  url: '',
  durationInDays: '10',
  roles: []
} as ReqPermissionsResponse

interface ReqPermissionsResponse {
  id: string
  name: string
  url: string
  durationInDays: string
  roles: string[]
}

interface ApiResponse {
  data: ReqPermissionsResponse
  error: string | null
}

interface FetchOptions {
  token: string
  supabaseClient: SupabaseClient
}

interface GetRequestPermissionsProps {
  state: RequestState
  error: string | null
  data?: ApiResponse['data']
  reset: () => unknown
  getRequestPermissions: (tenant: string, tenantEmail: string, fetchOption: FetchOptions, shouldMock: boolean) => Promise<ApiResponse>
}

const useGetRequestPermissions = create<GetRequestPermissionsProps>((set, get) => ({
  state: RequestState.IDLE,
  error: null,
  data: null,
  reset: () => {
    set({ state: RequestState.IDLE, error: null })
  },
  getRequestPermissions: async (tenant: string, tenantEmail: string, fetchOption: FetchOptions, shouldMock = false) => {
    set({ state: RequestState.LOADING, error: null, data: null })
    try {
      const apiFetch = buildFetch(fetchOption.supabaseClient)

      if (shouldMock) {
        set({ state: RequestState.SUCCESS, error: null, data: mockRequestPermissionsResponse })
        return {
          data: mockRequestPermissionsResponse,
          error: null
        }
      }

      const { data } = await apiFetch<ReqPermissionsResponse>(
        `${ENDPOINT}/partner/admin-request/${tenant}?email=${tenantEmail}`,
        buildOptions('GET', { Authorization: `Bearer ${fetchOption.token}` })
      )

      set({ state: RequestState.SUCCESS, error: null, data: data })

      return {
        data,
        error: null
      }
    } catch (_error) {
      const error = JSON.parse(_error.message).error
      set({ state: RequestState.ERROR, error })
      return {
        data: null,
        error
      }
    }
  }
}))

export { useGetRequestPermissions, ApiResponse as RequestPermissionsResponse }
