import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../utils'
import { AzureCustomerRecord, AzureCustomerRelationship } from './utils'

export const mockCustomers = [
  {
    id: '2a7064fb-1e33-4007-974e-352cb3f2c805',
    companyProfile: {
      companyName: 'Test Company with a longer name than most others',
      domain: 'superlongdomainname.com',
      "email": "a@a.com",
      "address": {
        "country": "US",
        "region": "wa",
        "city": "redmond",
        "addressLine1": "1 ms way",
        "postalCode": "98052",
        "phoneNumber": "1234567890"
      },
    },
    relationshipToPartner: AzureCustomerRelationship.RESELLER
  },
  {
    id: 'A235839f',
    companyProfile: {
      companyName: 'Test Company',
      domain: 'example.com',
      "email": "a@a.com",
      "address": {
        "country": "US",
        "region": "wa",
        "city": "redmond",
        "addressLine1": "1 ms way",
        "postalCode": "98052",
        "phoneNumber": "1234567890"
      },
    },
    relationshipToPartner: AzureCustomerRelationship.RESELLER
  },
  {
    id: 'A257839f',
    companyProfile: {
      companyName: 'Test Company 2',
      domain: 'example.com',
      "email": "a@a.com",
      "address": {
        "country": "US",
        "region": "wa",
        "city": "redmond",
        "addressLine1": "1 ms way",
        "postalCode": "98052",
        "phoneNumber": "1234567890"
      },
    },
    relationshipToPartner: AzureCustomerRelationship.RESELLER
  }
]

interface ApiResponse {
  data: AzureCustomerRecord[]
  error: string | null
}

interface FetchOptions {
  token: string
  supabaseClient: SupabaseClient
}

interface GetAzureCustomersProps {
  state: RequestState
  error: string | null
  data?: ApiResponse['data']
  reset: () => unknown
  getCustomers: (filterValue: string | null, fetchOption: FetchOptions, shouldMock: boolean) => Promise<ApiResponse>
}

const useGetAzureCustomers = create<GetAzureCustomersProps>((set, get) => ({
  state: RequestState.IDLE,
  error: null,
  data: [],
  reset: () => {
    set({ state: RequestState.IDLE, error: null })
  },
  getCustomers: async (filterValue: string | null, fetchOption: FetchOptions, shouldMock = false) => {
    set({ state: RequestState.LOADING, error: null, data: [] })
    try {
      const apiFetch = buildFetch(fetchOption.supabaseClient)

      if (shouldMock) {
        // simulate network for mocks
        await new Promise((done) => setTimeout(() => done(null), 1000))
        set({ state: RequestState.SUCCESS, error: null, data: mockCustomers })
        return {
          data: mockCustomers,
          error: null
        }
      }

      const filter = {
        field: 'CompanyName',
        value: filterValue,
        operator: 'starts_with'
      }

      let url = `${ENDPOINT}/customers`

      if (filterValue) {
        url = `${url}?${new URLSearchParams(filter).toString()}`
      }

      // TODO: use newResp=true to get the new response format with pagination when ready
      const { data } = await apiFetch<AzureCustomerRecord[]>(
        url,
        buildOptions('GET', { Authorization: `Bearer ${fetchOption.token}` })
      )

      set({ state: RequestState.SUCCESS, error: null, data })

      return {
        data,
        error: null
      }
    } catch (error) {
      const { error: _error } = error && error.message ? JSON.parse(error.message) : 'error'
      set({ state: RequestState.ERROR, error: _error.type })
      return {
        data: [],
        error: _error.type
      }
    }
  }
}))

export { useGetAzureCustomers }
