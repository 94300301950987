import { SupabaseClient } from '@supabase/supabase-js'

declare var ENV: string
export const API_PREFIX = '/api'
export const ENDPOINT =
  ENV === 'development' ? 'http://localhost:4000/api' : 'https://ui.operium.io/v1'

export enum RequestState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export function buildOptions(
  method: string = 'GET',
  headers: Record<string, string>,
  body?: RequestInit['body']
) {
  const options: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    credentials: 'include',
    body,
    method
  }

  return options
}

export function buildQuery(filters: Record<string, string | number>) {
  const query = new URLSearchParams()
  for (const key of Object.keys(filters)) {
    if (filters[key]) {
      query.append(key, filters[key].toString())
    }
  }

  return query
}

export type ApiResponse<T> = { data: T; error: null | string }

export const buildFetch =
  (supabaseClient: SupabaseClient) =>
  async <T>(endpoint: string, options?: RequestInit): Promise<ApiResponse<T>> => {
    try {
      const opts = options || buildOptions('GET', {})
      const res = await fetch(endpoint, opts)

      if (!res.ok) {
        return res.text().then((text) => {
          throw new Error(text)
        })
      }

      return res.json()
    } catch (error) {
      throw new Error(error)
    }
  }
