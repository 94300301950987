import * as React from 'react'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './tooltip'

function getTextWidth(text: string) { 
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = getComputedStyle(document.body).font;

  return context.measureText(text).width;
} 

interface TextOverflow {
  text: string
  renderTrigger: () => React.ReactNode
  containerWidth: number
}

function TextOverflow(props: TextOverflow) {
  const isOverflowing = props.containerWidth < getTextWidth(props.text)
  if (isOverflowing) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            {props.renderTrigger()}
          </TooltipTrigger>
          <TooltipContent>
            <p>{props.text}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }
  
  return (
    <p>{props.renderTrigger()}</p>
  )
}

export {
  TextOverflow
}