import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../../utils'

export const mockAppMfaCheck = {
  applications: [
    'Application 1',
    'Application 2',
    'Application 3'
  ]
}

export interface AppMfaCheck {
  applications: string[]
}

interface ApiResponse {
  data: AppMfaCheck | null
  error: string | null
}

interface FetchOptions {
  token: string
  supabaseClient: SupabaseClient
}

interface GetAppMfaCheck {
  state: RequestState
  error: string | null
  data?: ApiResponse['data']
  reset: () => unknown
  getAppMfaCheck: (fetchOption: FetchOptions, shouldMock: boolean) => Promise<ApiResponse>
}

const useGetAppMfaCheck = create<GetAppMfaCheck>((set, get) => ({
  state: RequestState.IDLE,
  error: null,
  data: null,
  reset: () => {
    set({ state: RequestState.IDLE, error: null })
  },
  getAppMfaCheck: async (fetchOption: FetchOptions, shouldMock = false) => {
    set({ state: RequestState.LOADING, error: null, data: null })
    try {
      const apiFetch = buildFetch(fetchOption.supabaseClient)

      if (shouldMock) {
        // simulate network for mocks
        await new Promise((done) => setTimeout(() => done(null), 1000))
        set({ state: RequestState.SUCCESS, error: null, data: mockAppMfaCheck })
        return {
          data: mockAppMfaCheck,
          error: null
        }
      }

      const { data } = await apiFetch<AppMfaCheck>(
        `${ENDPOINT}/partner/mfa/adoption`,
        buildOptions('GET', { Authorization: `Bearer ${fetchOption.token}` })
      )

      set({ state: RequestState.SUCCESS, error: null, data })

      return {
        data,
        error: null
      }
    } catch (error) {
      const { error: _error } = error && error.message ? JSON.parse(error.message) : 'error'
      set({ state: RequestState.ERROR, error: _error.type })
      return {
        data: null,
        error: _error.type
      }
    }
  }
}))

export { useGetAppMfaCheck }
