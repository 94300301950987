import React from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'
import { Session, SupabaseClient } from '@supabase/supabase-js'
import { ToastAction } from '@radix-ui/react-toast'

import {
  ActivityLog,
  AzureCustomerRecord,
  buildOptions,
  ENDPOINT, FraudEventResponse,
  RequestState,
  useFinalizeAdminRelationship,
  useGetActivityLogs,
  useGetAzureCustomers,
  useGetAzureCustomersMfaCheck,
  useGetAzureCustomersSubscription, useGetRequestPermissions, useVerifyAdminRelationship,
} from '../../store'
import { checkErrorsFor401 } from '../../utils/hooks/handle401'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/ui/tabs'
import { useToast } from '../../components/ui/toast/use-toast'
import { Button } from '../../components/ui/button'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../components/ui/tooltip'
import { DataTable } from '../../components/ui/data-table'
import { Alert as AlertComponent, AlertDescription, AlertTitle } from '../../components/ui/alert'
import { CheckCircledIcon, ExclamationTriangleIcon, PlusCircledIcon } from '@radix-ui/react-icons'
import { usePostTestEvent } from '../../store/partner/events/test-event'

const SHOULD_MOCK = false

const columns: ColumnDef<ActivityLog>[] = [
  {
    accessorKey: 'value[0].caller',
    header: () => <div className='text-left uppercase'>Caller</div>,
    size: 300,
    cell: ({ row }) => {
      const formatted = row.original.value[0].caller as string
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <div className='text-left font-medium truncate max-w-[270px]'>
                {formatted}
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>{formatted}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    }
  },
  {
    accessorKey: 'value[0].authorization.role',
    header: () => <div className='text-left uppercase'>Role</div>,
    size: 200,
    cell: ({ row }) => {
      const formatted = row.original.value[0].authorization.role as string
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <div className='text-left font-medium truncate max-w-[170px]'>{formatted}</div>
            </TooltipTrigger>
            <TooltipContent>
              <p>{formatted}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    }
  },
  {
    accessorKey: 'value[0].authorization.action',
    header: () => <div className='text-left uppercase'>Action</div>,
    size: 320,
    cell: ({ row }) => {
      const formatted = row.original.value[0].authorization.action as string
      return <div className='text-left font-medium'>{formatted}</div>
    }
  },
  {
    accessorKey: 'value[0].level',
    header: () => <div className='text-left uppercase'>Level</div>,
    size: 100,
    cell: ({ row }) => {
      const formatted = row.original.value[0].level as string
      return <div className='text-left font-medium'>{formatted}</div>
    }
  }
]

interface CustomerPageProps {
  supabaseClient: SupabaseClient
}

function CustomerPage(props: CustomerPageProps) {
  const { supabaseClient } = props
  const [customer, setCustomer] = React.useState({} as AzureCustomerRecord)
  const [isLoading, setLoading] = React.useState(true)
  const [needsFinalize, setNeedsFinalize] = React.useState(false)
  const [needsPermissions, setNeedsPermissions] = React.useState(true)

  const { id } = useParams()
  const { session } = useOutletContext<{ session: Session }>()
  const { toast, dismiss } = useToast()

  const getCustomers = useGetAzureCustomers((state) => state.getCustomers)
  const getCustomersError = useGetAzureCustomers((state) => state.error)
  const data = useGetAzureCustomers((state) => state.data)
  const isCustomersLoading = useGetAzureCustomers((state) => state.state)
  const resetCustomers = useGetAzureCustomers((state) => state.reset)

  const getCustomerSubscriptions = useGetAzureCustomersSubscription(state => state.getCustomersSubscriptions)
  const customerSubsError = useGetAzureCustomersSubscription(state => state.error)
  const resetCustomerSubs = useGetAzureCustomersSubscription(state => state.reset)
  const subsIsLoading = useGetAzureCustomersSubscription(state => state.state)

  const checkMfa = useGetAzureCustomersMfaCheck(state => state.checkMfa)
  const mfaCheckState = useGetAzureCustomersMfaCheck(state => state.state)
  const mfaCheckData = useGetAzureCustomersMfaCheck(state => state.data)
  const mfaCheckError = useGetAzureCustomersMfaCheck(state => state.error)
  const mfaReset = useGetAzureCustomersMfaCheck(state => state.reset)

  const getActivityLogs = useGetActivityLogs(state => state.getActivityLogs)
  const activityLogs = useGetActivityLogs(state => state.data)
  const activityLogsError = useGetActivityLogs(state => state.error)
  const activityLogsLoading = useGetActivityLogs(state => state.state)
  const resetActivityLogs = useGetActivityLogs(state => state.reset)

  const createTestEvent = usePostTestEvent((state) => state.actions.createTestEvent)
  const createTestEventState = usePostTestEvent((state) => state.state)
  const createTestEventError = usePostTestEvent((state) => state.error)

  const getVerifyAdminRelationship = useVerifyAdminRelationship(state => state.getVerifyAdminRelationship)
  const verifyState = useVerifyAdminRelationship((state) => state.state)
  const verifyData = useVerifyAdminRelationship((state) => state.data)
  const verifyError = useVerifyAdminRelationship((state) => state.error)

  const getRequestPermissions = useGetRequestPermissions((state) => state.getRequestPermissions)

  const requestFinalizeAdminRelationship = useFinalizeAdminRelationship((state) => state.finalizeAdminRelationship)

  React.useEffect(() => {
    async function loadData() {
      setLoading(true)
      // get customer
      let _customer = data.find((d) => d.id === id)
      if (!_customer) {
        // fetch customer by ID, page load or refresh
        const { data } = await getCustomers(null, { token: session.access_token, supabaseClient }, SHOULD_MOCK)
        _customer = data.find((d: any) => d.id === id)
      }

      if (_customer) {
        setCustomer(_customer)
        if(needsPermissions) {
          // const res = await getVerifyAdminRelationship(_customer.id, {token: session.access_token, supabaseClient})
          // if (!res.error) {
            setNeedsPermissions(false)
          // }
        }

        // get activity logs
        await getActivityLogs(_customer.id, {token: session.access_token, supabaseClient}, SHOULD_MOCK)

        // check MFA for customer
        const {data: customerSubs} = await getCustomerSubscriptions(_customer.id, {
          token: session.access_token,
          supabaseClient
        }, SHOULD_MOCK)
        const randomSub = customerSubs[0] // just pick one for now
        if (randomSub) {
          await checkMfa(_customer.id, randomSub.id, {token: session.access_token, supabaseClient}, SHOULD_MOCK)
        }
      }
      setLoading(false)
    }

    loadData()

    return () => {
      mfaReset()
      resetCustomers()
      resetCustomerSubs()
      resetActivityLogs()
    }
  }, [needsPermissions])

  checkErrorsFor401(
    [mfaCheckError, getCustomersError, verifyError, activityLogsError, customerSubsError],
    {
      url: `${ENDPOINT}/customers/${id}`,
      options: buildOptions('GET', { Authorization: `Bearer ${session.access_token}` }),
      data: {
        customerId: id
      }
    },
    props.supabaseClient,
    needsPermissions
  )

  async function _createTestEvent() {
    const options = { token: session.access_token, supabaseClient }
    await createTestEvent(options)
  }

  React.useEffect(() => {
    if (createTestEventState === RequestState.LOADING) {
      toast({
        title: (
          <div className='flex items-center w-full'>
            <div className='flex items-center h-full w-[5%]'>
              <span className='animate-ping absolute inline-flex h-[8px] w-[8px] rounded-full bg-op-blue-light opacity-75'></span>
            </div>
            <h1 className='ml-2 w-full'>Creating Test Event...</h1>
          </div>
        ) as any
      })
    }

    if (createTestEventState === RequestState.SUCCESS) {
      toast({
        title: (
          <div className='flex items-center'>
            <CheckCircledIcon width={24} height={24} className='text-green-500' />
            <p className='ml-2'>Test Event Created</p>
          </div>
        ) as any,
        action: (
          <ToastAction className='mt-4 self-end' altText='Close' onClick={() => dismiss()}>Close</ToastAction>
        ),
      })
    }

    if (createTestEventError) {
      toast({
        variant: 'destructive',
        title: 'Failed to create test event',
        description: createTestEventError,
        action: (
          <ToastAction className='mt-4 self-end' altText='Close' onClick={() => dismiss()}>Close</ToastAction>
        ),
      })
    }
  }, [createTestEventState, createTestEventError])

  async function finalizeAdminRelationship() {
    toast({
      title: (
          <div className='flex flex-col h-[50px]'>
            <h2>Building request...</h2>
            <div className='flex items-center h-full w-[40%] ml-2 mt-2'>
              <span className='animate-ping absolute inline-flex h-[8px] w-[8px] rounded-full bg-op-blue-light opacity-75'></span>
            </div>
          </div>
      ) as any,
    })

    try {
      const options = { token: session.access_token, supabaseClient }
      const { data, error } = await requestFinalizeAdminRelationship(customer.id, options)

      if (error) {
        toast({
          variant: 'destructive',
          title: 'Failed to finalize admin relationship',
          description: error,
          action: (
              <ToastAction className='mt-4 self-end' altText='Close' onClick={() => dismiss()}>Close</ToastAction>
          ),
        })
      } else {
        setNeedsFinalize(false)
        toast({
          title: 'Admin Relationship Finalization',
          description: (
              <div className='flex flex-col'>
                <p className='mb-2'>{`You can use this link to finalize the admin relationship`}</p>
                <a className='underline' target='_blank' rel='noopener noreferrer' href={data.responseUrl}>Finalize Relationship</a>
              </div>
          ),
          action: (
              <ToastAction className='mt-4 self-end' altText='Close' onClick={() => {
                dismiss()
                resetCustomerSubs()
              }}>Close</ToastAction>
          ),
        })
      }
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Failed to request admin relationship',
        description: JSON.stringify(error),
        action: (
            <ToastAction className='mt-4 self-end' altText='Close' onClick={() => dismiss()}>Close</ToastAction>
        )
      })
    }
  }

  async function requestAdminRelationship() {
    toast({
      title: (
          <div className='flex flex-col h-[50px]'>
            <h2>Building request...</h2>
            <div className='flex items-center h-full w-[40%] ml-2 mt-2'>
              <span className='animate-ping absolute inline-flex h-[8px] w-[8px] rounded-full bg-op-blue-light opacity-75'></span>
            </div>
          </div>
      ) as any,
    })

    try {
      const options = { token: session.access_token, supabaseClient }
      const { data, error } = await getRequestPermissions(customer.id, customer.companyProfile.email, options, false)

      if (error) {
        toast({
          variant: 'destructive',
          title: 'Failed to request admin relationship',
          description: error,
          action: (
              <ToastAction className='mt-4 self-end' altText='Close' onClick={() => dismiss()}>Close</ToastAction>
          ),
        })
      } else {
        setNeedsFinalize(true)
        setNeedsPermissions(false)

        toast({
          title: 'Admin Relationship Request Details',
          description: (
              <div className='flex flex-col'>
                <p className='mb-2'>{`You can use this link to request an admin relationship for ${data.durationInDays} days`}</p>
                <a className='underline' target='_blank' rel='noopener noreferrer' href={data.url}>Request Relationship</a>
              </div>
          ),
          action: (
              <ToastAction className='mt-4 self-end' altText='Close' onClick={() => dismiss()}>Close</ToastAction>
          ),
        })
      }
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Failed to request admin relationship',
        description: JSON.stringify(error),
        action: (
            <ToastAction className='mt-4 self-end' altText='Close' onClick={() => dismiss()}>Close</ToastAction>
        )
      })
    }
  }

  return (
    <div className='w-full h-full text-white grow basis-0 flex flex-col'>
      {isLoading ? (
        <div className='w-full h-full flex justify-center items-center'>
          <span className='animate-ping absolute inline-flex h-[10px] w-[10px] rounded-full bg-op-blue-light opacity-75'></span>
        </div>
      ): (
          <>
            <div className='flex justify-between mb-16 px-4 pt-4'>
              <div className='flex justify-between w-full'>
                <div className='flex flex-col w-full'>
                  <div className='flex justify-between w-full'>
                    <h1 className='text-3xl font-bold pb-2'>{customer.companyProfile.companyName}</h1>
                    <>
                      { needsPermissions ? (
                        <AlertComponent className='flex flex-col h-[150px] px-2 w-[350px] absolute right-4'>
                          <ExclamationTriangleIcon className='h-6 w-6 text-yellow-300' />
                          <AlertTitle className='uppercase pl-12'>Need Permissions</AlertTitle>
                          <AlertDescription className='pl-12'>
                            To run remediation actions, request admin relationship from customer.
                          </AlertDescription>
                          <Button className='uppercase mt-4' onClick={requestAdminRelationship}>Request Admin Relationship</Button>
                        </AlertComponent>
                      ) : needsFinalize ? (
                        <AlertComponent className='flex flex-col h-[150px] px-2 w-[350px] absolute right-4'>
                          <ExclamationTriangleIcon className='h-6 w-6 text-yellow-300' />
                          <AlertTitle className='uppercase pl-12'>Awaiting Permissions</AlertTitle>
                          <AlertDescription className='pl-12'>
                            To run remediation actions, finalize admin permissions once granted by customer.
                          </AlertDescription>
                          <Button className='uppercase mt-4' onClick={finalizeAdminRelationship}>Finalize Relationship</Button>
                        </AlertComponent>
                      ) : (<></>)}
                    </>
                  </div>
                  <p className='text-md uppercase'>
                    <b>{ customer.companyProfile.domain }</b>
                  </p>
                  <p className='text-sm uppercase font-thin mt-4'>
                    <b>Relationship:</b> {customer.relationshipToPartner}
                  </p>
                  <div className='text-sm uppercase font-thin flex items-center'>
                    <p><b>MFA Enabled:</b></p> {mfaCheckData && mfaCheckData.mfa_enabled || <ExclamationTriangleIcon className='text-yellow-500 ml-2' width={16} height={16} />}
                  </div>
                  <div className='mt-4'>
                    {customer.id === '948b218b-f129-4a6d-8b1d-e4e364cc0689' && (
                      <Button onClick={_createTestEvent}>
                        <PlusCircledIcon className='h-4 w-4 mr-3' />
                        <p className='uppercase'>Test Event</p>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='flex-[1] flex flex-col'>
              <Tabs defaultValue='activity' className='grow w-full'>
                <TabsList className='m-4'>
                  <TabsTrigger value='activity'>Activity</TabsTrigger>
                  <TabsTrigger value='provisioning'>Provisioning</TabsTrigger>
                  <TabsTrigger value='directory'>Directory Audit</TabsTrigger>
                  <TabsTrigger value='signins'>Sign Ins</TabsTrigger>
                </TabsList>
                <TabsContent value='activity'>
                  <DataTable data={activityLogs} columns={columns} isLoading={activityLogsLoading === RequestState.LOADING} />
                </TabsContent>
                <TabsContent value='provisioning'></TabsContent>
                <TabsContent value='directory'></TabsContent>
                <TabsContent value='signins'></TabsContent>
              </Tabs>
            </div>
          </>  
      )}
    </div>
  )
}

export { CustomerPage }
