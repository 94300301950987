import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../utils'

interface FinalizeResponse {
    responseUrl: string
}

interface ApiResponse {
    data: FinalizeResponse | null
    error: string | null
}

interface FetchOptions {
    token: string
    supabaseClient: SupabaseClient
}

interface FinalizeAdminRelationshipProps {
    state: RequestState
    error: string | null
    data?: ApiResponse['data']
    reset: () => unknown
    finalizeAdminRelationship: (tenant: string, fetchOption: FetchOptions) => Promise<ApiResponse>
}

const useFinalizeAdminRelationship = create<FinalizeAdminRelationshipProps>((set, get) => ({
    state: RequestState.IDLE,
    error: null,
    data: null,
    reset: () => {
        set({ state: RequestState.IDLE, error: null })
    },
    finalizeAdminRelationship: async (tenant: string, fetchOption: FetchOptions) => {
        set({ state: RequestState.LOADING, error: null, data: null })
        try {
            const apiFetch = buildFetch(fetchOption.supabaseClient)

            const { data } = await apiFetch<FinalizeResponse>(
                `${ENDPOINT}/partner/admin-finalize/${tenant}`,
                buildOptions('GET', { Authorization: `Bearer ${fetchOption.token}` })
            )

            set({ state: RequestState.SUCCESS, error: null, data: data })

            return {
                data,
                error: null
            }
        } catch (error) {
            set({ state: RequestState.ERROR, error: (error.message.split(':')[1] || '').trim() })
            return {
                data: null,
                error: (error.message.split(':')[1] || '').trim()
            }
        }
    }
}))

export { useFinalizeAdminRelationship, ApiResponse as FinalizeAdminRelationshipResponse }
