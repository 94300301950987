import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../utils'

interface VerifyAdminRelationshipResponse {
    satisfied?: { id: string, name: string }
    unsatisfied?: {
        roles: {
            id: string
            name: string
            description: string
        }[]
    }
}

interface ApiResponse {
    data: VerifyAdminRelationshipResponse | null
    error: string | null
}

interface FetchOptions {
    token: string
    supabaseClient: SupabaseClient
}

interface VerifyAdminRelationshipProps {
    state: RequestState
    error: string | null
    data?: ApiResponse['data']
    reset: () => unknown
    getVerifyAdminRelationship: (tenant: string, fetchOption: FetchOptions) => Promise<ApiResponse>
}

const useVerifyAdminRelationship = create<VerifyAdminRelationshipProps>((set, get) => ({
    state: RequestState.IDLE,
    error: null,
    data: null,
    reset: () => {
        set({ state: RequestState.IDLE, error: null })
    },
    getVerifyAdminRelationship: async (tenant: string, fetchOption: FetchOptions) => {
        set({ state: RequestState.LOADING, error: null, data: null })
        try {
            const apiFetch = buildFetch(fetchOption.supabaseClient)

            const { data } = await apiFetch<VerifyAdminRelationshipResponse>(
                `${ENDPOINT}/partner/admin-verify/${tenant}`,
                buildOptions('GET', { Authorization: `Bearer ${fetchOption.token}` })
            )

            const needsPermissions = data && data.unsatisfied && data.unsatisfied.roles.length > 0

            if(needsPermissions) {
                set({ state: RequestState.ERROR, error: 'access-customer', data: null })
                return {
                    data: null,
                    error: 'access-customer'
                }
            }

            set({ state: RequestState.SUCCESS, error: null, data: data })
            return {
                data,
                error: null
            }
        } catch (error) {
            set({ state: RequestState.ERROR, error: (error.message.split(':')[1] || '').trim() })
            return {
                data: null,
                error: (error.message.split(':')[1] || '').trim()
            }
        }
    }
}))

export { useVerifyAdminRelationship, ApiResponse as VerifyAdminRelationshipResponse }
