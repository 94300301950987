import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../../utils'
import { Runbook } from './utils'
import {FraudEventResponse} from "@/src/store";

export const ACTION_TO_DISPLAY: { [index: string]: string } = {
  collectActivityLogs: 'Collect Activity Logs',
  collectAuditLogs: 'Collect Audit Logs',
  containUser: 'Contain User',
  containPartnerUser: 'Contain Partner User',
  containVirtualMachine: 'Contain Virtual Machine'
}

export const ACTION_TO_DESCRIPTION: { [index: string]: string } = {
  collectActivityLogs: 'Activity logs provide an insight into the operations performed on each Azure resource in the subscription from the outside, known as the management plane. in addition to updates on Service Health events.',
  collectAuditLogs: 'Audit Logs are a comprehensive report on every logged event in Microsoft Entra ID. Changes to applications, groups, users, and licenses are all captured in the Microsoft Entra audit logs.',
  containUser: 'Containing a user includes disabling a user\'s account, revoking active sessions, resetting the user\'s password and notifying the user.',
  containPartnerUser: 'Containing a partner user includes resetting the user\s password, and revoking active sessions.',
  containVirtualMachine: 'Containing a VM includes collecting logs, deallocating the VM, and storing its disks for investigation.'
}

export const mockRunbook = {
  "id": "3fa5476f-4f59-4a1b-ad48-a2737d084e01",
  "tenantId": "3a9f36a1-730d-4a94-b4b8-e3df2d556842",
  "subscriptionId": "26837a46-e6c2-4bac-8233-63b0e8b2dd67",
  "eventType": "NetworkConnectionsToCryptoMiningPools",
  "actions": [
      {
          "action": "containVirtualMachine",
          "resource": {
              "id": "/subscriptions/subscription-id/resourceGroups/resourcegroup-name/providers/Microsoft.Compute/virtualMachines/vm-name",
              "name": "vm-name",
              "resourceGroup": "resourcegroup-name",
              "subscriptionId": "subscription-id"
          }
      },
      {
          "action": "collectActivityLogs",
          "tenant_id": "3a9f36a1-730d-4a94-b4b8-e3df2d556842",
          "subscription_id": "26837a46-e6c2-4bac-8233-63b0e8b2dd67"
      },
      {
          "action": "collectAuditLogs",
          "tenant_id": "3a9f36a1-730d-4a94-b4b8-e3df2d556842"
      }
  ],
  "generated": [
      "### Rapid Response\n1. Login to Operium.io\n1. Click on https://operium.io/alerts/[alert_id]\n1. Examine related information\n1. Choose \"remediate\" from Operium portal\n1. Choose \"Download IR Artifacts\" from Operium portal\n \n### Manual Response\n1. Assess\n   1. Get details\n   1. Shutdown affected resources\n   1. Isolate user accounts\n   1. Capture network traffic\n   1. Block\n   1. Triage and collect logs\n1. Respond\n   1. Root cause analysis\n   1. Remove unauthorized or unknown software\n   1. Restore the resources from trusted backup\n   1. Review any unauthorized changes made to the resources\n   1. User access logs\n   1. Limiting resources to observed traffic\n1. Recovery\n   1. Monitor\n   1. Slowly introduce the resources into the production\n   1. Watch for any signs of re-infection\n   1. If no issues arise from introducing the resources, then watch them for some time"
  ]
} as Runbook

interface ApiResponse {
  data: Runbook
  error: string | null
}

interface FetchOptions {
  token: string
  supabaseClient: SupabaseClient
}

interface GetRunbookProps {
  state: RequestState
  error: string | null
  data?: ApiResponse['data']
  reset: () => unknown
  getRunbook: (fraudEvent: FraudEventResponse, fetchOption: FetchOptions, shouldMock: boolean) => Promise<ApiResponse>
}

const useGetRunbook = create<GetRunbookProps>((set, get) => ({
  state: RequestState.IDLE,
  error: null,
  data: null,
  reset: () => {
    set({ state: RequestState.IDLE, data: null, error: null })
  },
  getRunbook: async (fraudEvent: FraudEventResponse, fetchOption: FetchOptions, shouldMock = false) => {
    set({ state: RequestState.LOADING, data: null, error: null })
    try {
      const apiFetch = buildFetch(fetchOption.supabaseClient)

      if (shouldMock) {
        // simulate network for mocks
        await new Promise((done) => setTimeout(() => done(null), 1000))
        set({ state: RequestState.SUCCESS, error: null, data: mockRunbook })
        return {
          data: mockRunbook,
          error: null
        }
      }

      const postEvent = {
          fraudEvent: fraudEvent
      }

      const { data } = await apiFetch<Runbook>(
        `${ENDPOINT}/runbook`,
        buildOptions('POST', { Authorization: `Bearer ${fetchOption.token}` }, JSON.stringify(postEvent))
      )

      set({ state: RequestState.SUCCESS, error: null, data })

      return {
        data,
        error: null
      }
    } catch (error) {
      const { error: _error } = error && error.message ? JSON.parse(error.message) : 'error'
      set({ state: RequestState.ERROR, error: _error.type })
      return {
        data: null,
        error: _error.type
      }
    }
  }
}))

export { useGetRunbook }
