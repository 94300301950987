import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../utils'
import { AzureCustomerRecord, AzureCustomerRelationship } from './utils'

export const mockCustomer = {
  id: '2a7064fb-1e33-4007-974e-352cb3f2c805',
  companyProfile: {
    companyName: 'Test Company with a longer name than most others',
    domain: 'superlongdomainname.com',
    "email": "a@a.com",
    "address": {
      "country": "US",
      "region": "wa",
      "city": "redmond",
      "addressLine1": "1 ms way",
      "postalCode": "98052",
      "phoneNumber": "1234567890"
    },
  },
  relationshipToPartner: AzureCustomerRelationship.RESELLER
}

interface ApiResponse {
  data: AzureCustomerRecord | null
  error: string | null
}

interface FetchOptions {
  token: string
  supabaseClient: SupabaseClient
}

interface GetAzureCustomerProps {
  state: RequestState
  error: string | null
  data?: ApiResponse['data']
  reset: () => unknown
  getCustomer: (customerId: string, fetchOption: FetchOptions, shouldMock: boolean) => Promise<ApiResponse>
}

const useGetAzureCustomer = create<GetAzureCustomerProps>((set, get) => ({
  state: RequestState.IDLE,
  error: null,
  data: null,
  reset: () => {
    set({ state: RequestState.IDLE, error: null })
  },
  getCustomer: async (customerId: string, fetchOption: FetchOptions, shouldMock = false) => {
    set({ state: RequestState.LOADING, error: null, data: null })
    try {
      const apiFetch = buildFetch(fetchOption.supabaseClient)

      if (shouldMock) {
        // simulate network for mocks
        await new Promise((done) => setTimeout(() => done(null), 1000))
        set({ state: RequestState.SUCCESS, error: null, data: mockCustomer })
        return {
          data: mockCustomer,
          error: null
        }
      }

      const { data } = await apiFetch<AzureCustomerRecord>(
        `${ENDPOINT}/customers/${customerId}`,
        buildOptions('GET', { Authorization: `Bearer ${fetchOption.token}` })
      )

      set({ state: RequestState.SUCCESS, error: null, data })

      return {
        data,
        error: null
      }
    } catch (error) {
      const { error: _error } = error && error.message ? JSON.parse(error.message) : 'error'
      set({ state: RequestState.ERROR, error: _error.type })
      return {
        data: null,
        error: _error.type
      }
    }
  }
}))

export { useGetAzureCustomer }
