import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../../utils'

// TODO: what's the runbook schema
export const mockRunbooks = [
  {
    id: '348e932d-ef58-4347-8351-be51e4ec148c'
  }
] as Runbook[]

interface Runbook {
  id: string
}

interface ApiResponse {
  data: Runbook[]
  error: string | null
}

interface FetchOptions {
  token: string
  supabaseClient: SupabaseClient
}

interface GetRunbookProps {
  state: RequestState
  error: string | null
  data?: Runbook[]
  reset: () => unknown
  getRunbooks: (fetchOption: FetchOptions, shouldMock: boolean) => Promise<ApiResponse>
}

const useGetRunbooks = create<GetRunbookProps>((set, get) => ({
  state: RequestState.IDLE,
  error: null,
  data: [],
  reset: () => {
    set({ state: RequestState.IDLE, error: null })
  },
  getRunbooks: async (fetchOption: FetchOptions, shouldMock = false) => {
    set({ state: RequestState.LOADING, error: null, data: [] })
    try {
      const apiFetch = buildFetch(fetchOption.supabaseClient)

      if (shouldMock) {
        set({ state: RequestState.SUCCESS, error: null, data: mockRunbooks })
        return {
          data: mockRunbooks,
          error: null
        }
      }

      const { data } = await apiFetch<Runbook[]>(
        `${ENDPOINT}/runbooks`,
        buildOptions('GET', { Authorization: `Bearer ${fetchOption.token}` })
      )

      set({ state: RequestState.SUCCESS, error: null, data: data })

      return {
        data,
        error: null
      }
    } catch (error) {
      const { error: _error } = error && error.message ? JSON.parse(error.message) : 'error'
      set({ state: RequestState.ERROR, error: _error.type })
      return {
        data: [],
        error: _error.type
      }
    }
  }
}))

export { useGetRunbooks }
