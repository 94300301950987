function buildConfig(config: Record<string, string>) {
  const supabaseKey = config['SUPABASE_ANON_KEY'] || process.env['SUPABASE_ANON_KEY']
  if (!supabaseKey) {
    throw new Error('SUPABASE_ANON_KEY not set')
  }
  const supabaseUrl = config['SUPABASE_URL'] || process.env['SUPABASE_URL']
  if (!supabaseUrl) {
    throw new Error('SUPABASE_URL not set')
  }
  const apiUrl = config['API_URL'] || process.env['API_URL']
  if (!apiUrl) {
    throw new Error('API_URL not set')
  }

  return {
    supabaseKey,
    supabaseUrl,
    apiUrl
  }
}

export { buildConfig }
