
export enum AzureCustomerRelationship {
  ADVISOR = 'advisor',
  MICROSOFT_SUPPORT = 'microsoft_support',
  NONE = 'none',
  RESELLER = 'reseller',
  SYNDICATION = 'syndication',
  UNKNOWN = 'unknown'
}

export interface AzureCustomerRecord {
  id: string
  companyProfile: {
    companyName: string
    domain: string
    email: string
    address: {
      country: string
      region: string
      city: string
      addressLine1: string
      postalCode: string
      phoneNumber: string
    }
  }
  relationshipToPartner: AzureCustomerRelationship
}