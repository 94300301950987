import React from 'react'
import { useOutletContext, useParams, useNavigate } from 'react-router-dom'
import { Session, SupabaseClient } from '@supabase/supabase-js'
import Markdown from 'react-markdown'
import { CheckCircledIcon, ExclamationTriangleIcon, LightningBoltIcon } from '@radix-ui/react-icons'
import { ToastAction } from '@radix-ui/react-toast'

import {
  ACTION_TO_DESCRIPTION,
  ACTION_TO_DISPLAY,
  ActionType,
  AdditionalInfoType,
  buildOptions,
  CollectActivityLogsAction,
  CollectAuditLogsAction,
  ContainVMAction,
  DownloadLogsBody,
  ENDPOINT,
  FraudEventResponse,
  OptionOk,
  PortalUrlsBody,
  RemediationResponse,
  useGetPartnerCenterEvents,
  useGetRequestPermissions,
  useGetRunbook,
  usePostRemediation,
  useVerifyAdminRelationship,
} from '../../store'


import { EVENT_NAME_TO_DISPLAY } from '../../utils/display'
import { checkErrorsFor401 } from '../../utils/hooks/handle401'

import { Button } from '../../components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog'
import { useToast } from '../../components/ui/toast/use-toast'
import { Checkbox } from '../../components/ui/checkbox'
import {
  Sheet,
  SheetContent,
  SheetClose,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../../components/ui/sheet'
import { Alert as AlertComponent, AlertDescription, AlertTitle } from '../../components/ui/alert'

enum Step {
  CollectAdProvsiioningLogs = 'CollectAdProvsiioningLogs',
  CollectAdDirectoryAudits = 'CollectAdDirectoryAudits',
  CollectAdSignInLogs = 'CollectAdSignInLogs',
  CollectSubscriptionActivtyLogs = 'CollectSubscriptionActivtyLogs',
  CollectResourceLogs = 'CollectResourceLogs',
  DeallocateVirtualMachine = 'DeallocateVirtualMachine',
  CaptureVirtualMachineImage = 'CaptureVirtualMachineImage',
  CaptureVirtualMachineDisks = 'CaptureVirtualMachineDisks'
}

const STEP_TO_DISPLAY: { [index: string]: string } = {
  [Step.CaptureVirtualMachineImage]: 'Capture Virtual Machine Image',
  [Step.CaptureVirtualMachineDisks]: 'Capture Virtual Machine Disks',
  [Step.CollectResourceLogs]: 'Collect Resource Logs',
  [Step.DeallocateVirtualMachine]: 'Deallocate Virtual Machine',
  [Step.CollectSubscriptionActivtyLogs]: 'Collect Subscription Activity Logs',
  [Step.CollectAdSignInLogs]: 'Collect Sign In Logs',
  [Step.CollectAdDirectoryAudits]: 'Collect Directory Audit Logs',
  [Step.CollectAdProvsiioningLogs]: 'Collect Provisioning Logs',
}

const SHOULD_MOCK = false

interface EventPageProps {
  supabaseClient: SupabaseClient
}

function EventPage(props: EventPageProps) {
  const { supabaseClient } = props
  const [resultDialogOpen, setResultDialogOpen] = React.useState(false)
  const [resultData, setResultData] = React.useState([] as RemediationResponse[])
  const [selectedActions, setSelectedActions] = React.useState([])
  const [alert, setAlert] = React.useState(null as FraudEventResponse | null)

  const { id } = useParams()
  const { toast, dismiss } = useToast()
  const { session } = useOutletContext<{ session: Session }>()

  const getNotifications = useGetPartnerCenterEvents((state) => state.getNotifications)
  const data = useGetPartnerCenterEvents((state) => state.data)
  const error = useGetPartnerCenterEvents((state) => state.error)

  const getRunbook = useGetRunbook((state) => state.getRunbook)
  const runbook = useGetRunbook((state) => state.data)
  const runbookState = useGetRunbook((state) => state.state)
  const getRunbookError = useGetRunbook((state) => state.error)

  const remediate = usePostRemediation((state) => state.actions.remediate)
  const remediateState = usePostRemediation((state) => state.state)
  const remediateError = usePostRemediation((state) => state.error)

  const getVerifyAdminRelationship = useVerifyAdminRelationship(state => state.getVerifyAdminRelationship)
  const verifyState = useVerifyAdminRelationship((state) => state.state)
  const verifyData = useVerifyAdminRelationship((state) => state.data)
  const verifyError = useVerifyAdminRelationship((state) => state.error)

  const [isLoading, setLoading] = React.useState(true)

  React.useEffect(() => {
    async function _getData() {
      setLoading(true)
      let match = data.find((d) => d.eventId === id)
      if (!match) {
        // fetch event by ID, page load or refresh
        const { data } = await getNotifications({ token: session.access_token, supabaseClient }, SHOULD_MOCK)
        match = data.find((d: any) => d.eventId === id)
        if (match) {
          setAlert(match)
        }
      } else {
        setAlert(match)
      }
      if(match) {
        console.log(`FraudEvent=${JSON.stringify(match)}`)
        // const verifyRes = await getVerifyAdminRelationship(match.customerTenantId, {
        //   token: session.access_token,
        //   supabaseClient
        // })
        // if(!verifyRes.error) {
          await getRunbook(match, {token: session.access_token, supabaseClient}, SHOULD_MOCK)
          setLoading(false)
        // }
      }
    }

    _getData()
  }, [])

  const navigate = useNavigate()
  React.useEffect(() => {
    if(verifyError === 'access-customer') {
      navigate(`/app/customers/${alert.customerTenantId}`)
    }
  }, [verifyError, alert])

  checkErrorsFor401(
      [error, getRunbookError, remediateError],
      {
        url: `${ENDPOINT}/events/${id}`,
        options: buildOptions('GET', { Authorization: `Bearer ${session.access_token}` }),
      },
      props.supabaseClient
  )

  async function runRunbook() {
    if(!alert) {
      toast({
        variant: 'destructive',
        title: 'Failed to execute runbook',
        description: 'No available error',
      })
    }
    toast({
      title: (
        <div className='flex flex-col h-[50px]'>
          <h2>Submitting actions...</h2>
          <div className='flex items-center h-full w-[40%] ml-2 mt-2'>
            <span className='animate-ping absolute inline-flex h-[8px] w-[8px] rounded-full bg-op-blue-light opacity-75'></span>
          </div>
        </div>
      ) as any,
    })

    const payload = { tenantId: alert.customerTenantId, eventId: id, dryRun: false, actions: selectedActions.map(action => JSON.parse(action)) }
    const options = { token: session.access_token, supabaseClient }
    const { data, error } = await remediate(payload, options)

    if (data && data.results) {
      setResultData(data.results)

      toast({
        title: (
            <div className='flex items-center'>
              <CheckCircledIcon className='text-green-500'/>
              <p className='ml-2'>Actions Executed</p>
            </div>
        ) as any,
        description: 'Click "View Actions" to view runbook results',
        action: (
            <ToastAction
                className='rounded p-2 font-bold text-xs border-none bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80 mt-4 self-end'
                altText='View Actions'
                onClick={() => {
                  dismiss()
                  setResultDialogOpen(true)
                }}>
              View Actions
            </ToastAction>
        )
      })
    } else if (error == 'access-customer') {
      toast({
        title: 'Customer Login Required',
        description: (
            <div className='flex flex-col'>
              <p className='mb-2'>Remediation Requires Customer Login</p>
            </div>
        ),
        action: (
            <ToastAction className='mt-4 self-end' altText='Close' onClick={() => {
              dismiss()
              navigate(`/app/customers/${alert.customerTenantId}`)
            }}>Close</ToastAction>
        ),
      })
    } else {
      toast({
        variant: 'destructive',
        title: 'Failed to execute runbook',
        description: JSON.stringify(error),
      })
    }
  }

  function toggleActionSelection(action: any) {
    const actionKey = JSON.stringify(action)

    if (selectedActions.includes(actionKey)) {
      const newSelectedActions = selectedActions.filter(a => a !== actionKey)
      setSelectedActions(newSelectedActions)
    } else {
      const newSelectedActions = [...selectedActions, actionKey]
      setSelectedActions(newSelectedActions)
    }
  }

  return (
    <div className='w-full h-full p-4 text-white grow basis-0 flex flex-col overflow-scroll'>
      {isLoading ? (
        <div className='w-full h-full flex justify-center items-center'>
          <span className='animate-ping absolute inline-flex h-[10px] w-[10px] rounded-full bg-op-blue-light opacity-75'></span>
        </div>
      ): (
        <>
          <div className='flex justify-between'>
            <div className='flex justify-between w-full'>
              <div className='flex flex-col'>
                <h1 className='text-3xl font-bold pb-2'>{EVENT_NAME_TO_DISPLAY[alert.eventType] || alert.eventType}</h1>
                <div className='flex w-full'>
                  <p className='text-md uppercase mb-4 mr-8'>
                    severity: <b>{alert.severity}</b>
                  </p>
                  <p className='text-md uppercase mb-4 mr-8'>
                    confidence: <b>{alert.confidenceLevel}</b>
                  </p>
                  <p className='text-md uppercase mb-4'>
                    status: <b>{alert.eventStatus}</b>
                  </p>
                </div>
                <p className='text-md'>{alert.displayName}</p>
                <p className='text-sm mb-4'>{alert.description}</p>
                <div className='flex my-4'>
                  <div className='w-full h-full rounded flex-[1] py-6 mr-2 grid grid-cols-3 gap-x-2 gap-y-6'>
                    <div className='text-sm uppercase'>
                      <p>
                        <b>Customer Name</b>
                      </p>
                      <p className='font-thin truncate'>{alert.customerFriendlyName}</p>
                    </div>
                    <div className='text-sm uppercase'>
                      <p>
                        <b>Service</b>
                      </p>
                      <p className='font-thin'>
                        {alert.serviceName}
                      </p>
                    </div>
                    <div className='text-sm uppercase'>
                      <p>
                        <b>Resource</b>
                      </p>
                      <p className='font-thin'>
                        {alert.resourceName}
                      </p>
                    </div>
                    <div className='text-sm uppercase'>
                      <p>
                        <b>Subscription</b>
                      </p>
                      <p className='font-thin truncate'>
                        {alert.subscriptionName}
                      </p>
                    </div>
                    <div className='text-sm uppercase'>
                      <p>
                        <b>Subscription Type</b>
                      </p>
                      <p className='font-thin'>
                        {alert.subscriptionType}
                      </p>
                    </div>
                    <div className='text-sm uppercase'>
                      <p>
                        <b>Hit Count</b>
                      </p>
                      <p className='font-thin'>
                        {alert.hitCount}
                      </p>
                    </div>
                  </div>
                  <div className='w-full h-full flex-[1] py-6 ml-2 grid grid-cols-3 gap-x-2 gap-y-6'>
                    <div className='text-sm uppercase'>
                      <p>
                        <b>Resource Group</b>
                      </p>
                      <p className='font-thin truncate'>{alert.resourceGroupName}</p>
                    </div>
                    <div className='text-sm uppercase'>
                      <p>
                        <b>Catalog Offer ID</b>
                      </p>
                      <p className='font-thin'>
                        {alert.catalogOfferId}
                      </p>
                    </div>
                    <div className='text-sm uppercase'>
                      <p>
                        <b>Entity</b>
                      </p>
                      <p className='font-thin'>
                        {alert.entityName}
                      </p>
                    </div>
                    <div className='text-sm uppercase'>
                      <p>
                        <b>Event Time</b>
                      </p>
                      <p className='font-thin'>
                      {new Date(alert.eventTime).toLocaleString('en-us')}
                      </p>
                      </div>
                    <div className='text-sm uppercase'>
                      <p>
                        <b>Last Seen</b>
                      </p>
                      <p className='font-thin'>{new Date(alert.lastOccurrence).toLocaleString('en-us')}</p>
                    </div>
                    <div className='text-sm uppercase'>
                      <p>
                        <b>First Seen</b>
                      </p>
                      <p className='font-thin'>{new Date(alert.firstOccurrence).toLocaleString('en-us')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex justify-end items-center mt-4'>
            <Sheet>
              <div className='w-full flex justify-between items-end pb-2'>
                <h3 className='text-2l font-bold'>Generated Runbook</h3>    
                <div className='flex flex-col'>
                  <SheetTrigger onClick={() => setSelectedActions([])}>
                    <Button>
                      <LightningBoltIcon className='h-4 w-4 mr-3' />
                      <p className='uppercase'>View Actions</p>
                    </Button>
                  </SheetTrigger>
                </div>
              </div>
              <SheetContent className='w-[400px] sm:w-[540px] flex flex-col'>
                <SheetHeader>
                  <SheetTitle>Confirm Runbook Actions</SheetTitle>
                </SheetHeader>
                <div className='flex flex-col w-full h-full text-sm text-muted-foreground'>
                  <p className='mb-8'>
                    Some actions may be destructive, please review the following actions and select the actions to perform.
                  </p>
                    <div className='flex flex-col flex-[10] border-t border-slate-50 border-opacity-40 overflow-y-scroll'>
                    {runbook.actions.map(action => (
                      <div key={action.action} className='mt-4'>
                        <ActionSelector
                          action={action}
                          selectedActions={selectedActions}
                          isDisabled={false}
                          toggleActionSelection={toggleActionSelection}
                        />
                      </div>
                    ))}    
                  </div>
                  <div className='flex flex-[1] justify-between items-center border-t border-slate-50 border-opacity-40 pt-4'>
                    <div className='flex items-center'>
                    </div>
                    <SheetClose asChild>
                      <Button className='uppercase' onClick={runRunbook}>submit</Button>
                    </SheetClose>
                  </div>    
                </div>
              </SheetContent>
            </Sheet>
          </div>
          <div className='flex-[1] flex flex-col backdrop-blur bg-white/10 px-4 py-6 rounded'>
            <pre className='h-full overflow-auto text-sm whitespace-pre-wrap font-fira-code runbook-markdown'>
              <Markdown>{runbook.generated[0]}</Markdown>
            </pre>
          </div>
          <Dialog open={resultDialogOpen} onOpenChange={setResultDialogOpen}>
            <DialogContent className='max-h-[75%] overflow-scroll'>
              <DialogHeader>
                <DialogTitle>Runbook Results</DialogTitle>
                <DialogDescription>
                  {resultData.map((result: RemediationResponse, ind: number) => (
                    <RemediationResult key={ind} remediationResult={result} />
                  ))}
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  )
}

interface RemediationResultProps {
  remediationResult: RemediationResponse
}

function RemediationResult(props: RemediationResultProps) {
  function downloadLogs(log: string, name: string) {
    const file = new File([log], `resource-logs-${name}`, {
      type: 'text/plain',
    })
    
    const link = document.createElement('a')
    const url = URL.createObjectURL(file)
  
    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()
  
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  function renderResult(result: RemediationResponse) {
    switch (result.action.action) {
      case ActionType.containVirtualMachine: {
        return (
          <div className='flex flex-col'>
            <p className='text-xs uppercase'>
              <b>Virtual Machine: </b>{ result.action.resource.name }
            </p>
            <p className='text-xs uppercase'>
              <b>Resource Group: </b>{ result.action.resource.resourceGroup }
            </p>
            <p className='text-xs uppercase'>
              <b>Subscription ID: </b>{ result.action.resource.subscriptionId }
            </p>
          </div>
        )
      }
      case ActionType.collectAuditLogs: {
        return (
          <div className='flex flex-col'>
            <p className='text-xs uppercase'>
              <b>Tenant ID: </b>{ result.action.tenant_id }
            </p>
            {/* <p key={step.step} className='text-xs mb-2 underline uppercase' onClick={() => downloadLogs(step.result.Ok.details)}>download logs</p> */}
          </div>
        )
      }
      case ActionType.collectActivityLogs: {
        return (
          <div className='flex flex-col'>
            <p className='text-xs uppercase'>
              <b>Subscription ID: </b>{ result.action.subscription_id }
            </p>
          </div>
        )
      }
      default: {
        return (
          <div />
        )
      }
    }
  }
  return (
    <div className='mt-4 mb-12'>
      {renderResult(props.remediationResult)}
      <h3 className='text-xs uppercase mt-2'><b>Steps</b></h3>
      {props.remediationResult.steps.map((step, stepInd) => {
        return (
          <>
            <div className='flex flex-col text-xs mt-2' key={step.step + stepInd}>
              <p>{STEP_TO_DISPLAY[step.step] || step.step}</p>
              {('Ok' in step.result) && (
                <>
                  {/*<p>{step.result.Ok.details}</p>*/}
                  {parseOkResult(step.result)}
                </>
              )}
              {('Err' in step.result) && (
                <p>{step.result.Err.error}</p>
              )}
              {('SubscriptionRequired' in step.result) && (
                  <>
                    <p>Subscription Required({step.result.SubscriptionRequired.reason})</p>
                    <a target='_blank' rel='noopener noreferrer' className='underline' href={step.result.SubscriptionRequired.redirect}>Manual Step Required</a>
                  </>
              )}
            </div>
          </>
        )
      })}
    </div>
  )
}

function parseOkResult(res: OptionOk) {
  switch (res.Ok?.additionalInfo?.type) {
    case AdditionalInfoType.DOWNLOAD_LOGS:
      const dl = res.Ok.additionalInfo.body as DownloadLogsBody
      return (<a className='underline' href={dl.downloadUrl}>Download Logs</a>)
    case AdditionalInfoType.PORTAL_LINKS:
      const portal = res.Ok.additionalInfo.body as PortalUrlsBody
      const data = []
      for (const link of portal.links) {
        data.push(<a className='underline' target='_blank' href={link.url}>{link.name}</a>)
      }
      return data
    default:
      return (
        <p>{res.Ok.details}</p>
      )

  }
}

interface ActionSelector {
  action: ContainVMAction | CollectActivityLogsAction | CollectAuditLogsAction
  selectedActions: string[]
  isDisabled: boolean
  toggleActionSelection: (action: any) => unknown
}

function ActionSelector(props: ActionSelector) {
  const { action, isDisabled, selectedActions, toggleActionSelection } = props
  const actionKey = JSON.stringify(action)
  switch (action.action) {
    case 'collectAuditLogs': {
      return (
        <>
          <p className='text-xs uppercase'>
            <b>Tenant ID: </b>{ action.tenant_id }
          </p>
          <div className='text-xs'>
            <b className='uppercase'>Actions: </b>
            <div className='flex mt-2 mb-4 cursor-pointer' onClick={() => toggleActionSelection(action)}>
              <Checkbox
                disabled={isDisabled}
                className='mt-[3px]'
                checked={selectedActions.includes(actionKey)} />
              <div className='flex flex-col ml-2'>
                <h3 className='font-bold' key={action.action}>
                  {ACTION_TO_DISPLAY[action.action] || action.action}
                </h3>
                <p>{ACTION_TO_DESCRIPTION[action.action] || ''}</p>
              </div>
            </div>
          </div>
        </>
      )
    }
    case 'collectActivityLogs': {
      return (
        <>
          <p className='text-xs uppercase'>
            <b>Subscription ID: </b>{ action.subscription_id }
          </p>
          <div className='text-xs'>
            <b className='uppercase'>Actions: </b>
            <div className='flex mt-2 mb-4 cursor-pointer' onClick={() => toggleActionSelection(action)}>
              <Checkbox
                disabled={isDisabled}
                className='mt-[3px]'
                checked={selectedActions.includes(actionKey)} />
              <div className='flex flex-col ml-2'>
                <h3 className='font-bold' key={action.action}>
                  {ACTION_TO_DISPLAY[action.action] || action.action}
                </h3>
                <p>{ACTION_TO_DESCRIPTION[action.action] || ''}</p>
              </div>
            </div>
          </div>
        </>
      )
    }
    case 'containVirtualMachine':
      return (
        <>
          <p className='text-xs uppercase'>
            <b>Resource: </b>{ action.resource.name }
          </p>
          <p className='text-xs uppercase'>
            <b>Resource Group: </b>{ action.resource.resourceGroup }
          </p>
          <p className='text-xs uppercase'>
            <b>Subscription ID: </b>{ action.resource.subscriptionId }
          </p>
          <div className='text-xs'>
            <b className='uppercase'>Actions: </b>
            <div className='flex mt-2 mb-4 cursor-pointer' onClick={() => toggleActionSelection(action)}>
              <Checkbox
                disabled={isDisabled}
                className='mt-[3px]'
                checked={selectedActions.includes(actionKey)} />
              <div className='flex flex-col ml-2'>
                <h3 className='font-bold' key={action.action}>
                  {ACTION_TO_DISPLAY[action.action] || action.action}
                </h3>
                <p>{ACTION_TO_DESCRIPTION[action.action] || ''}</p>
              </div>
            </div>
          </div>
        </>
      )
    default:
      return (
        <div />
      )
  }
}

export { EventPage }
