import React from 'react'
import { createRoot } from 'react-dom/client'
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
  useLocation,
  NavLink,
} from 'react-router-dom'
import { createClient, Session, SupabaseClient } from '@supabase/supabase-js'
import {
  BellIcon,
  ComponentBooleanIcon,
  ExclamationTriangleIcon,
  PersonIcon,
} from '@radix-ui/react-icons'
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary'

import { useGetOrg, ENDPOINT, buildFetch, buildOptions } from './store'
import { Login, CustomersPage, CustomerPage, EventsPage, EventPage } from './pages'
import { buildConfig } from './config'
import { Database } from './types/supabase'
import { Logo } from './components/logo'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from './components/ui/dropdown'
import { Button } from './components/ui/button'
import { Toaster } from './components/ui/toast/toaster'

import './tailwind.css'

function UnexpectedError() {
  const { resetBoundary } = useErrorBoundary()
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
    setTimeout(() => {
      resetBoundary()
    }, 100)
  }
  return (
    <div className='w-full h-full flex flex-col justify-center items-center'>
      <ExclamationTriangleIcon className='text-yellow-500' width={32} height={32} />
      <h2 className='text-white text-xl font-bold mb-4'>Unexpected Error!</h2>
      <Button onClick={goBack}>Go Back</Button>
    </div>
  )
}

interface LayoutProps {
  supabaseClient: SupabaseClient
}

function Layout(props: LayoutProps) {
  const [context, setContext] = React.useState({})
  const [isLoading, setLoading] = React.useState(true)
  const navigate = useNavigate()
  const { hash, pathname } = useLocation()
  const apiFetch = buildFetch(props.supabaseClient)
  const { getOrg } = useGetOrg()

  const [session, setSession] = React.useState(null as Session | null)
  const [userOrg, setUserOrg] = React.useState(null as string | null)

  async function logout() {
    const { error } = await props.supabaseClient.auth.signOut()
    if (error) {
      console.log(error)
    } else {
      return navigate('/', { replace: true })
    }
  }

  async function connectAzure() {
    if (!session) {
      throw new Error('no jwt available')
    }
    const options = buildOptions(
      'GET',
      { Authorization: `Bearer ${session.access_token}` },
    )
    const { data } = await apiFetch(`${ENDPOINT}/login`, options)
    location.href = data as string
  }

  React.useEffect(() => {
    async function _getToken() {
      // this should be the redirect from magic link case
      let session
      if (hash) {
        const [_, params] = hash.split('#')
        const searchParams = new URLSearchParams(params)
        const refreshToken = searchParams.get('refresh_token')

        try {
          const { data, error } = await props.supabaseClient.auth.refreshSession({
            refresh_token: refreshToken
          })

          if (!data.session || error) {
            return navigate('/', { replace: true })
          }

          session = data.session
          setSession(data.session)
          setContext({ session: data.session })
        } catch (error) {
          return navigate('/', { replace: true })
        }
      } else {
        const { data, error } = await props.supabaseClient.auth.getSession()

        if (!data.session || error) {
          return navigate('/', { replace: true })
        }

        session = data.session
        setSession(data.session)
        setContext({ session: data.session })
      }

      if (session.user.app_metadata.organizations) {
        const org = session.user.app_metadata.organizations[0]
        const { data, error } = await getOrg(org, {
          token: session.access_token,
          supabaseClient: props.supabaseClient
        })

        if (error) {
          console.log(error)
        }
        setUserOrg(data.data.name)
      }
      setLoading(false)
    }

    _getToken()
  }, [])

  return (
    <div className='flex h-full w-full bg-op-blue-dark'>
      <div className='w-full h-full flex'>
        <div className='flex-none w-64 h-full p-4 border-r border-slate-50 border-opacity-40'>
          <div className='flex items-center'>
            <Logo width={36} height={36} />
            <h1 className='text-op-blue-light font-serif uppercase text-3xl ml-4'>Operium</h1>
          </div>
          <ul className='mt-10'>
            <li className='w-full h-12'>
              <NavLink
                to='/app/customers'
                className={({ isActive, isPending }) =>
                  isPending
                    ? ''
                    : isActive || pathname === '/app'
                    ? 'px-4 py-2 rounded backdrop-blur bg-white/10 uppercase text-white flex items-center hover:bg-white/5 font-bold'
                    : 'px-4 py-2 rounded bg-transparent uppercase text-white flex items-center hover:bg-white/5 font-bold'
                }>
                <PersonIcon className='h-4 w-4 mr-3' />
                <p className='text-sm'>customers</p>
              </NavLink>
            </li>
            <li className='w-full h-12'>
              <NavLink
                to='/app/events'
                className={({ isActive, isPending }) =>
                  isPending
                    ? ''
                    : isActive
                    ? 'px-4 py-2 rounded backdrop-blur bg-white/10 uppercase text-white flex items-center hover:bg-white/5 font-bold'
                    : 'px-4 py-2 rounded uppercase text-white flex items-center hover:bg-white/5 font-bold'
                }>
                <BellIcon className='h-4 w-4 mr-3' />
                <p className='text-sm'>events</p>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className='grow h-full flex flex-col'>
          <div className='flex-none w-full h-12 px-4 py-2 border-b border-slate-50 border-opacity-40 flex justify-end items-center'>
            {!isLoading && session.user && (
              <div className='mr-8'>
                <p className='text-white text-xs'>{session.user.email}</p>
                {userOrg && (
                  <p className='text-slate-500 text-xs'>
                    Logged in for: <b>{userOrg}</b>
                  </p>
                )}
              </div>
            )}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant='ghost' className='h-6 w-6 p-0 float-right text-white'>
                  <ComponentBooleanIcon />
                  <span className='sr-only'>Open menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align='end' className='bg-black border-none rounded-sm'>
                <DropdownMenuItem className='focus:bg-black'>
                  <Button
                    variant='ghost'
                    className='w-full justify-start text-white'
                    onClick={connectAzure}>
                    Login Partner Account
                  </Button>
                </DropdownMenuItem>
                <DropdownMenuItem className='focus:bg-black'>
                  <Button
                    variant='ghost'
                    className='w-full justify-start text-white'
                    onClick={logout}>
                    Logout
                  </Button>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          {!isLoading && (
            <ErrorBoundary fallback={<UnexpectedError />}>
              <Outlet context={context} />
            </ErrorBoundary>
            )}
          <Toaster />
        </div>
      </div>
    </div>
  )
}

declare var ENV: string
function App() {
  const config = {
    SUPABASE_ANON_KEY:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InR5amx5Y3dtdW91a2Z4ZXJveXJiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTY3OTU2MTQsImV4cCI6MjAxMjM3MTYxNH0.M8ECVKpX2HsQCQ9HUmDHlBcvidLCazAmzaZha-M5y0I',
    SUPABASE_URL: 'https://tyjlycwmuoukfxeroyrb.supabase.co',
    API_URL: 'https://ui.operium.io/v1'
  }
  const configStaging = {
    SUPABASE_ANON_KEY:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlkdnphdmRvdmVzbnJwdWx5aWdoIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTU0MTQxNjAsImV4cCI6MjAxMDk5MDE2MH0.5-hRNT-pin6gFjv1L1cF84CpyFyAMzakaHgTKxguy-c',
    SUPABASE_URL: 'https://idvzavdovesnrpulyigh.supabase.co',
    API_URL: 'http://localhost:4000/api/v1'
  }

  const _conf = ENV === 'development' ? configStaging : config
  const conf = buildConfig(_conf)

  const supabase = createClient<Database>(conf.supabaseUrl, conf.supabaseKey)

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login supabaseClient={supabase} />}></Route>
        <Route path='/app' element={<Layout supabaseClient={supabase} />}>
          <Route index element={<CustomersPage supabaseClient={supabase} />} />
          <Route path='customers' element={<CustomersPage supabaseClient={supabase} />} />
          <Route path='customers/:id' element={<CustomerPage supabaseClient={supabase} />} />
          <Route path='events' element={<EventsPage supabaseClient={supabase} />} />
          <Route path='events/:id' element={<EventPage supabaseClient={supabase} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
