import React from 'react'
import { SupabaseClient } from '@supabase/supabase-js'
import { MagicLink } from '@supabase/auth-ui-react'

interface LoginProps {
  supabaseClient: SupabaseClient
}

export function Login(props: LoginProps) {
  return (
    <div className='w-full h-full grid grid-cols-fluid text-white'>
      <div className='w-full h-full bg-center bg-cover bg-[url("/static/img/login-bg-tiny.svg")]'></div>
      <div className='flex w-full h-full bg-gradient-to-b from-[#018FAE] via-[#04748C] to-slate-950'>
        <div className='flex flex-col justify-center pl-24'>
          <h1 className='text-4xl font-semibold leading-none tracking-tight'>Nice to see you!</h1>
          <div className='pt-8'>
            <MagicLink
              providers={[]}
              redirectTo={`${window.location.href}app`}
              supabaseClient={props.supabaseClient}
              appearance={{
                className: {
                  button:
                    'rounded p-2 bg-op-blue-light border-none hover:outline hover:outline-1 hover:outline-white hover:!bg-op-blue-light',
                  input:
                    'rounded p-2 backdrop-blur bg-white/10 border-none hover:outline hover:outline-1 hover:border-white hover:!bg-white/10'
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className='absolute bottom-[20px] left-[calc(50%+6rem)] w-[242px] flex justify-center'>
        <p className='text-gray-500'>© {new Date().getFullYear()}, Operium Inc.</p>
      </div>
    </div>
  )
}
