import React from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { SupabaseClient, Session } from '@supabase/supabase-js'
import { ColumnDef } from '@tanstack/react-table'
import { LockOpen2Icon } from '@radix-ui/react-icons'

import {
  AzureCustomerRecord,
  buildOptions,
  ENDPOINT,
  RequestState,
  useGetAppMfaCheck,
  useGetAzureCustomers,
} from '../../store'
import { checkErrorsFor401 } from '../../utils/hooks/handle401'

import { DataTable } from '../../components/ui/data-table'
import { TextOverflow } from '../../components/ui/text-overflow'
import { Input } from '../../components/ui/input'
import { Button } from '../../components/ui/button'

const SHOULD_MOCK = false

const columns: ColumnDef<AzureCustomerRecord>[] = [
  {
    accessorKey: 'companyProfile.companyName',
    header: () => <div className='text-left uppercase'>Company Name</div>,
    size: 300,
    cell: ({ row }) => {
      const formatted = row.original.companyProfile as AzureCustomerRecord['companyProfile']
      return (
        <TextOverflow
          renderTrigger={() => (
            <div className='text-left font-medium truncate max-w-[270px]'>
              <Link to={`/app/customers/${row.original.id}`} className='text-left font-medium'>
                {formatted.companyName}
              </Link>
            </div>
          )}
          text={formatted.companyName}
          containerWidth={300}
        />
      )
    }
  },
  {
    accessorKey: 'companyProfile.domain',
    header: () => <div className='text-left uppercase'>Domain</div>,
    size: 200,
    cell: ({ row }) => {
      const formatted = row.original.companyProfile as AzureCustomerRecord['companyProfile']
      return (
        <TextOverflow
          renderTrigger={() => (
            <div className='text-left font-medium truncate max-w-[170px]'>
              <Link to={`/app/customers/${row.original.id}`} className='text-left font-medium'>
                {formatted.domain}
              </Link>
            </div>
          )}
          text={formatted.domain}
          containerWidth={200}
        />
      )
    }
  },
  {
    accessorKey: 'id',
    header: () => <div className='text-left uppercase'>ID</div>,
    size: 320,
    cell: ({ row }) => {
      const formatted = row.getValue('id') as string
      return (
        <TextOverflow
          renderTrigger={() => (
            <div className='text-left font-medium truncate max-w-[290px]'>
              <Link to={`/app/customers/${row.original.id}`} className='text-left font-medium'>
                {formatted}
              </Link>
            </div>
          )}
          text={formatted}
          containerWidth={320}
        />
      )
    }
  },
  {
    accessorKey: 'relationshipToPartner',
    header: () => <div className='text-left uppercase'>Relationship</div>,
    size: 100,
    cell: ({ row }) => {
      const formatted = row.getValue('relationshipToPartner') as string
      return (
        <TextOverflow
          renderTrigger={() => (
            <div className='text-left font-medium truncate max-w-[70px]'>
              <Link to={`/app/customers/${row.original.id}`} className='text-left font-medium'>
                {formatted}
              </Link>
            </div>
          )}
          text={formatted}
          containerWidth={100}
        />
      )
    }
  }
]

interface CustomersPageProps {
  supabaseClient: SupabaseClient
}

export function CustomersPage(props: CustomersPageProps) {
  const { supabaseClient } = props
  const { session } = useOutletContext<{ session: Session }>()
  const [filter, setFilter] = React.useState('')

  const getCustomers = useGetAzureCustomers((state) => state.getCustomers)
  const reset = useGetAzureCustomers((state) => state.reset)
  const data = useGetAzureCustomers((state) => state.data)
  const error = useGetAzureCustomers((state) => state.error)
  const state = useGetAzureCustomers((state) => state.state)

  const getAppMfaCheck = useGetAppMfaCheck(state => state.getAppMfaCheck)
  const mfaCheckLoading = useGetAppMfaCheck(state => state.state)
  const appsNeedFullMFA = useGetAppMfaCheck(state => state.data)
  const resetMfaCheck = useGetAppMfaCheck(state => state.reset)
  const mfaCheckError = useGetAppMfaCheck(state => state.error)

  checkErrorsFor401(
    [error, mfaCheckError],
    {
      url: `${ENDPOINT}/customers`,
      options: buildOptions('GET', { Authorization: `Bearer ${session.access_token}` }),
    },
    props.supabaseClient
  )

  React.useEffect(() => {
    async function _getData() {
      const fetchOptions = { token: session.access_token, supabaseClient }
      await getCustomers(null, fetchOptions, SHOULD_MOCK)
      await getAppMfaCheck(fetchOptions, SHOULD_MOCK)
    }
    _getData()

    return () => {
      reset()
      resetMfaCheck()
    }
  }, [])

  async function searchCustomer() {
    const fetchOptions = { token: session.access_token, supabaseClient }
    await getCustomers(filter, fetchOptions, SHOULD_MOCK)
  }

  const mfaisLoading = mfaCheckLoading === RequestState.IDLE || mfaCheckLoading === RequestState.LOADING

  return (
    <>
      <div className='flex justify-end flex-none w-full h-64 p-4'>
        <div className='rounded backdrop-blur bg-white/10 h-full w-[30%] text-white p-2'>
          <div className='flex items-center mb-4'>
            <LockOpen2Icon />
            <h2 className='font-md uppercase font-bold ml-2'>
              Full MFA Adoption needed
            </h2>
          </div>
          {mfaisLoading ? (
            <div className='w-full h-full flex justify-center pt-16'>
              <span className='animate-ping absolute inline-flex h-[10px] w-[10px] rounded-full bg-op-blue-light opacity-75'></span>
            </div>
          ): (
            <div className='w-full h-full overflow-y-scroll'>
              {(appsNeedFullMFA && appsNeedFullMFA.applications || []).map(appName => (
                <p className='text-sm italic'>{appName}</p>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className='grow w-full'>
        <div className='flex flex-[1] items-center px-4 pb-4 text-white'>
          <Input
            placeholder='...'
            value={filter}
            onChange={(event) =>
              setFilter(event.target.value)
            }
            className='max-w-xs mr-4'
          />
          <Button onClick={searchCustomer}>Search</Button>
        </div>
        <DataTable data={data} columns={columns} isLoading={state === RequestState.LOADING} />
      </div>
    </>
  )
}
