import { create } from 'zustand'
import { SupabaseClient } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../utils'

// we are actually talking to the azure mgmt api here, not partner center, but we should map the values to the same type so this works
export const mockSubscriptions = [
  {
    "id": "83ef9d05-4169-4ef9-9657-0e86b1eab1de",
    "entitlementId": "a356ac8c-e310-44f4-bf85-C7f29044af99",
    "friendlyName": "nickname",
    "quantity": 1,
    "unitType": "none",
    "creationDate": "2015-11-25T06: 41: 12Z",
    "effectiveStartDate": "2015-11-24T08: 00: 00Z",
    "commitmentEndDate": "2016-12-12T08: 00: 00Z",
    "status": "active",
    "autoRenewEnabled": false,
    "billingType": "none",
    "contractType": "subscription",
    "links": {
      "offer": {
        "uri": "/v1/offers/0CCA44D6-68E9-4762-94EE-31ECE98783B9",
        "method": "GET",
        "headers": []
      },
      "self": {
        "uri": "/subscriptions?key=<key>",
        "method": "GET",
        "headers": []
      }
    },
    "orderId": "6183db3d-6318-4e52-877e-25806e4971be",
    "attributes": {
      "etag": "<etag>",
      "objectType": "Subscription"
    }
  } as any
]

export interface ListResponse<T> {
  next: string | null
  value: T[]
}

interface AzureMgmtCustomerSubscriptionRecord {
  id: string
  displayName: string
  subscriptionId: string
}

export interface AzureCustomerSubscriptionRecord {
  id: string
  friendlyName: string
}

interface ApiResponse {
  data: AzureCustomerSubscriptionRecord[]
  error: string | null
}

interface FetchOptions {
  token: string
  supabaseClient: SupabaseClient
}

interface GetAzureCustomerSubscriptionProps {
  state: RequestState
  error: string | null
  data?: ApiResponse['data']
  reset: () => unknown
  getCustomersSubscriptions: (customerId: string, fetchOption: FetchOptions, shouldMock: boolean) => Promise<ApiResponse>
}

const useGetAzureCustomersSubscription = create<GetAzureCustomerSubscriptionProps>((set, get) => ({
  state: RequestState.IDLE,
  error: null,
  data: [],
  reset: () => {
    set({ state: RequestState.IDLE, error: null })
  },
  getCustomersSubscriptions: async (customerId: string, fetchOption: FetchOptions, shouldMock = false) => {
    set({ state: RequestState.LOADING, error: null, data: [] })
    try {
      const apiFetch = buildFetch(fetchOption.supabaseClient)

      if (shouldMock) {
        // simulate network for mocks
        await new Promise((done) => setTimeout(() => done(null), 1000))
        set({ state: RequestState.SUCCESS, error: null, data: mockSubscriptions })
        return {
          data: mockSubscriptions,
          error: null
        }
      }

      const { data: resp } = await apiFetch<ListResponse<AzureMgmtCustomerSubscriptionRecord>>(
        `${ENDPOINT}/customer/${customerId}/subscription`,
        buildOptions('GET', { Authorization: `Bearer ${fetchOption.token}` })
      )

      const data = resp.value.map((item) => { return { id: item.subscriptionId, friendlyName: item.displayName } as AzureCustomerSubscriptionRecord })


      set({ state: RequestState.SUCCESS, error: null, data })

      return {
        data,
        error: null
      }
    } catch (error) {
      const { error: _error } = error && error.message ? JSON.parse(error.message) : 'error'
      set({ state: RequestState.ERROR, error: _error.type })
      return {
        data: [],
        error: _error.type
      }
    }
  }
}))

export { useGetAzureCustomersSubscription }
