import React from 'react'
import { Link, useLocation, useOutletContext } from 'react-router-dom'
import { SupabaseClient, Session } from '@supabase/supabase-js'
import { ColumnDef } from '@tanstack/react-table'

import {
  buildOptions,
  ENDPOINT,
  FraudEventResponse,
  RequestState,
  useGetPartnerCenterEvents,
} from '../../store'
import { Error401, use401Handlers } from '../../utils/hooks/handle401'
import { EVENT_NAME_TO_DISPLAY } from '../../utils/display'

import { DataTable } from '../../components/ui/data-table'
import { TextOverflow } from '../../components/ui/text-overflow'

const SHOULD_MOCK = false

const columns: ColumnDef<FraudEventResponse>[] = [
  {
    accessorKey: 'eventType',
    header: () => <div className='text-left uppercase'>Event</div>,
    size: 300,
    cell: ({ row }) => {
      const formatted = row.getValue('eventType') as string
      const display = EVENT_NAME_TO_DISPLAY[formatted] || formatted
      return (
        <TextOverflow
          renderTrigger={() => (
            <div className='text-left font-medium truncate max-w-[270px]'>
              <Link to={`/app/events/${row.original.eventId}`} className='text-left font-medium'>
                {display}
              </Link>
            </div>
          )}
          text={display}
          containerWidth={300}
        />
      )
    }
  },
  {
    accessorKey: 'eventStatus',
    header: () => <div className='text-lef uppercase'>Status</div>,
    size: 80,
    cell: ({ row }) => {
      const formatted = row.getValue('eventStatus') as string
      return (
        <TextOverflow
        renderTrigger={() => (
          <div className='text-left font-medium truncate max-w-[270px]'>
            <Link to={`/app/events/${row.original.eventId}`} className='text-left font-medium'>
              {formatted}
            </Link>
          </div>
        )}
        text={formatted}
        containerWidth={300}
      />
      )
    }
  },
  {
    accessorKey: 'customerFriendlyName',
    header: () => <div className='text-left uppercase'>Customer</div>,
    size: 200,
    cell: ({ row }) => {
      const formatted = row.getValue('customerFriendlyName') as string
      return (
        <TextOverflow
        renderTrigger={() => (
          <div className='text-left font-medium truncate max-w-[170px]'>
            <Link to={`/app/events/${row.original.eventId}`} className='text-left font-medium'>
              {formatted}
            </Link>
          </div>
        )}
        text={formatted}
        containerWidth={200}
      />
      )
    }
  },
  {
    accessorKey: 'partnerFriendlyName',
    header: () => <div className='text-lef uppercase'>Partner</div>,
    size: 200,
    cell: ({ row }) => {
      const formatted = row.getValue('partnerFriendlyName') as string
      return (
        <TextOverflow
        renderTrigger={() => (
          <div className='text-left font-medium truncate max-w-[170px]'>
            <Link to={`/app/events/${row.original.eventId}`} className='text-left font-medium'>
              {formatted}
            </Link>
          </div>
        )}
        text={formatted}
        containerWidth={200}
      />
      )
    }
  },
  {
    accessorKey: 'lastOccurrence',
    header: () => <div className='text-left uppercase'>Last Occurence</div>,
    size: 200,
    cell: ({ row }) => {
      const formatted = row.original.lastOccurrence as string
      const date = new Date(formatted).toLocaleString('en-us')
      return (
        <TextOverflow
        renderTrigger={() => (
          <div className='text-left font-medium truncate max-w-[170px]'>
            <Link to={`/app/events/${row.original.eventId}`} className='text-left font-medium'>
              {date}
            </Link>
          </div>
        )}
        text={date}
        containerWidth={200}
      />
      )
    }
  },
  {
    accessorKey: 'hitCount',
    header: () => <div className='text-left uppercase'>Hit Count</div>,
    size: 120,
    cell: ({ row }) => {
      const formatted = row.getValue('hitCount') as string
      return (
        <TextOverflow
        renderTrigger={() => (
          <div className='text-left font-medium truncate max-w-[90px]'>
            <Link to={`/app/events/${row.original.eventId}`} className='text-left font-medium'>
              {formatted}
            </Link>
          </div>
        )}
        text={formatted}
        containerWidth={120}
      />
      )
    }
  }
]

interface EventsPageProps {
  supabaseClient: SupabaseClient
}

export function EventsPage(props: EventsPageProps) {
  const { supabaseClient } = props

  const { search } = useLocation()
  const { session } = useOutletContext<{ session: Session }>()
  const getNotifications = useGetPartnerCenterEvents((state) => state.getNotifications)
  const reset = useGetPartnerCenterEvents((state) => state.reset)
  const data = useGetPartnerCenterEvents((state) => state.data)
  const error = useGetPartnerCenterEvents((state) => state.error)
  const state = useGetPartnerCenterEvents((state) => state.state)

  use401Handlers(
    error as Error401,
    {
      url: `${ENDPOINT}/events`,
      options: buildOptions('GET', { Authorization: `Bearer ${session.access_token}` })
    },
    props.supabaseClient
  )

  React.useEffect(() => {
    async function _getData() {
      // TODO: use query params filter in query when available
      const queryParams = new URLSearchParams(search)
      await getNotifications({ token: session.access_token, supabaseClient }, SHOULD_MOCK)
    }
    _getData()

    return () => {
      reset()
    }
  }, [])

  return (
    <>
      <div className='flex-none w-full h-64 p-4'>
      </div>
      <div className='grow w-full'>
        <DataTable data={data} columns={columns} isLoading={state === RequestState.LOADING} />
      </div>
    </>
  )
}
