import { create } from 'zustand'
import { SupabaseClient, User } from '@supabase/supabase-js'

import { ENDPOINT, buildOptions, RequestState, buildFetch } from '../utils'

interface ApiResponse {
  data: any // TODO
  error: string | null
}

interface FetchOptions {
  token: string
  supabaseClient: SupabaseClient
}

interface GetOrgProps {
  state: RequestState
  error: string | null
  data?: User
  reset: () => unknown
  getOrg: (orgId: string, fetchOption: FetchOptions) => Promise<ApiResponse>
}

const useGetOrg = create<GetOrgProps>((set, get) => ({
  state: RequestState.IDLE,
  error: null,
  data: null,
  reset: () => {
    set({ state: RequestState.IDLE, error: null })
  },
  getOrg: async (orgId: string, fetchOption: FetchOptions) => {
    set({ state: RequestState.LOADING, error: null, data: null })
    try {
      const apiFetch = buildFetch(fetchOption.supabaseClient)

      const data = await apiFetch<User>(
        `${ENDPOINT}/organization?org_id=${orgId}`,
        buildOptions('GET', { Authorization: `Bearer ${fetchOption.token}` })
      )

      set({ state: RequestState.SUCCESS, error: null, data: data.data })

      return {
        data,
        error: null
      }
    } catch (error) {
      const { error: _error } = error && error.message ? JSON.parse(error.message) : 'error'
      set({ state: RequestState.ERROR, error: _error.type })
      return {
        data: null,
        error: _error.type
      }
    }
  }
}))

export { useGetOrg }
