import React from 'react'
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState
} from '@tanstack/react-table'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './table'

interface DataTableProps<TData, TValue> {
  isLoading?: boolean
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

export function DataTable<TData, TValue>({
  isLoading: isLoadingProp,
  columns,
  data
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [tableHeight, setTableHeight] = React.useState(null)
  const tableRef = React.useCallback((node: any) => {
    if (node !== null) {
      setTableHeight(node.getBoundingClientRect().height)
    }
  }, [])

  const isLoading = isLoadingProp || false

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      pagination: {
        pageSize: 200,
        pageIndex: 0
      },
      sorting,
      columnFilters
    }
  })

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='flex-[10] border-t border-slate-50 border-opacity-40' ref={tableRef}>
        <Table className='text-white'>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className='hover:bg-white/5'>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      style={{
                        width: header.getSize(),
                        minWidth: header.getSize()
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          {isLoading ? (
            <TableBody style={{ height: tableHeight }}>
              <TableRow>
                <TableCell colSpan={columns.length} className='h-48 w-[100vw]'>
                  <div className='w-full h-full flex justify-center items-center'>
                    <span className='animate-ping absolute inline-flex h-[10px] w-[10px] rounded-full bg-op-blue-light opacity-75'></span>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody style={{ height: tableHeight }}>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <TableCell
                          key={cell.id}
                          style={{
                            width: cell.column.columnDef.size
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className='h-24 text-center w-[100vw]'>
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </div>
    </div>
  )
}
